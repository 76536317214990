import React, { useEffect, useState } from 'react'
import { UserCheck, CreditCard, Monitor, Users, Plus, Info, Loader, FileText, ThumbsUp  } from 'react-feather'
import { Link } from "react-router-dom";
import axios from 'axios';
import Config from '../Config.json';
import UsersSection from '../Components/UsersSection';




const Dashboard = (props) => {
  const [reseller, setReseller] = useState(""); 
  const [frCount, setFRCount] = useState(0); 
  const [fr2Count, setFR2Count] = useState(0); 
  const [fr3Count, setFR3Count] = useState(0); 
  const [deCount, setDECount] = useState(0); 
  const [de2Count, setDE2Count] = useState(0); 
  const [de3Count, setDE3Count] = useState(0); 
  const [nlCount, setNLCount] = useState(0); 
  const [plCount, setPLCount] = useState(0); 
  const [usCount, setUSCount] = useState(0); 
  const [ukCount, setUKCount] = useState(0); 
  const [fiCount, setFICount] = useState(0);
  const [fi2Count, setFI2Count] = useState(0);



  const frTotal = (length) => {
    setFRCount(length);
  };
  const fr2Total = (length) => {
    setFR2Count(length);
  };
  const fr3Total = (length) => {
    setFR3Count(length);
  };
  const deTotal = (length) => {
    setDECount(length);
  };
  const de2Total = (length) => {
    setDE2Count(length);
  };
  const de3Total = (length) => {
    setDE3Count(length);
  };
  const nlTotal = (length) => {
    setNLCount(length);
  };
  const plTotal = (length) => {
    setPLCount(length);
  };
  const usTotal = (length) => {
    setUSCount(length);
  };
  const ukTotal = (length) => {
    setUKCount(length);
  };
  const fiTotal = (length) => {
    setFICount(length);
  };
  const fi2Total = (length) => {
    setFI2Count(length);
  };

  useEffect(() => {      
    const headers = {
      headers: {
        "Content-Type": "application/json",
        "token": `${props.token}`
      }
    };
    axios.get(`${Config.API_URL}/reseller`, headers)
    .then (result => {
      setReseller(result.data);
    })
    .catch(err => {
        console.error(err);
    });
  },[reseller, props.token, frCount, deCount, de2Count, usCount, fiCount, nlCount, plCount, fi2Count, fr2Count, fr3Count, de3Count, ukCount]);
      
      

  return (
    <div className='lg:mx-20 lg:px-20'>
      <div className='flex flex-row bg-blue-700 text-white px-5 items-center justify-between py-12 rounded-b'>
        <h1 className=''>{props.title}</h1>
        <Link to="/Users">
          <button className='flex flex-row items-center justify-center bg-white text-blue-700 pl-2 pt-1 pb-1 pr-2 rounded border border-blue-500 transition hover:bg-blue-700 hover:text-white hover:border hover:border-white hover:transition'>
            <UserCheck width="16" height="16" className='mx-1' /> بررسی وضعیت کاربر
          </button>
        </Link>
      </div>
      <div className='p-5 flex flex-row justify-between items-center mt-10 md:-mt-12 mb-0 w-full flex-wrap md:flex-nowrap'>
        <div className='flex flex-col items-stretch justify-center w-full bg-white rounded flex-column p-5 border shadow-md mb-5 sm:flex-basis-1 md:flex-basis-1/3 hover:shadow-none transition ease-linear duration-300 hover:duration:300 hover:transition hover:ease-linear'>
          <div className='flex flex-row justify-between items-center w-100'>
            <h2>اعتبار</h2>
            <CreditCard className='bg-blue-100 text-blue-700 p-1 rounded' width="32" height="32" />
          </div>
          <div className='flex flex-row items-center justify-between text-gray-600 mt-5'>
            <Link to="/AddCredit">
              <div className='text-blue-500 flex flex-row items-center justify-start'>
                <FileText width="16" height="16" className='ml-1' /> گزارش اعتبار
              </div>
            </Link>
            <div className='count-number'>
              {
                !reseller ? 
                <Loader className='animate-spin animate-speed-100 text-gray-400' size={64} />
                : reseller.credit
              }
            </div>
          </div>
        </div>
        <div className='flex flex-col items-stretch justify-center w-full md:mx-3 bg-white rounded flex-column p-5 border shadow-md mb-5 sm:flex-basis-1 md:flex-basis-1/3 hover:shadow-none transition ease-linear duration-300 hover:duration:300 hover:transition hover:ease-linear'>
          <div className='flex flex-row justify-between items-center w-100'>
            <h2>سرور</h2>
            <Monitor className='bg-blue-100 text-blue-700 p-1 rounded' width="32" height="32" />
          </div>
          <div className='flex flex-row items-center justify-between text-gray-600 mt-5'>
            <Link to="/Servers">
              <div className='text-blue-500 flex flex-row items-center justify-start'>
                <Info width="16" height="16" className='ml-1' /> اطلاعات بیشتر
              </div>
            </Link>
            <div className='count-number'>
              11
            </div>
          </div>
        </div>
        <div className='flex flex-col items-stretch justify-center w-full bg-white rounded flex-column p-5 border shadow-md mb-5 sm:flex-basis-1 md:flex-basis-1/3 hover:shadow-none transition ease-linear duration-300 hover:duration:300 hover:transition hover:ease-linear'>
          <div className='flex flex-row justify-between items-center w-100'>
            <h2>کاربران</h2>
            <Users className='bg-blue-100 text-blue-700 p-1 rounded' width="32" height="32" />
          </div>
          <div className='flex flex-row items-center justify-between text-gray-600 mt-5'>
            <Link to="/Create">
              <div className='text-blue-500 flex flex-row items-center justify-start'>
                <Plus width="16" height="16" className='ml-1' /> افزودن کاربر
              </div>
            </Link>
            <div className='count-number'>
              {Number(frCount) + Number(deCount) + Number(de2Count) + Number(nlCount) + Number(plCount) + Number(usCount) + Number(fiCount) + Number(fi2Count) + Number(fr2Count) + Number(fr3Count) + Number(de3Count) + Number(ukCount)}
            </div>
          </div>
        </div>
      </div>
      <div className='px-5 flex flex-row justify-center items-center my-3 w-full'>
        <div className='p-5 bg-purple-200 rounded w-full'>
              <div className='fw-bold text-purple-800 flex flex-row items-center justify-start'>
                <ThumbsUp size={16} className="ml-3" /> اطلاعیه
              </div>
              <div className='text-purple-800 flex flex-row items-center justify-start'>
                امکان حذف کاربرانی که اعتبار و یا حجم آنها به اتمام رسیده فراهم شد.
              </div>
        </div>
      </div>
      <UsersSection token={props.token} credit={reseller.credit} prefix={reseller.prefix} frCount={frTotal} usCount={usTotal} deCount={deTotal} de2Count={de2Total} nlCount={nlTotal} plCount={plTotal} fiCount={fiTotal} fi2Count={fi2Total} fr2Count={fr2Total} fr3Count={fr3Total} de3Count={de3Total} ukCount={ukTotal} />
    </div>
  )
}

export default Dashboard
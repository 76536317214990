import React, { useState } from 'react'
import FranceUsers from './FranceUsers'
import FranceUsers2 from './FranceUsers2'
import NetherlandsUsers from './NetherlandsUsers';
import PolandUsers from './PolandUsers';
import GermanyUsers from './GermanyUsers';
import Germany2Users from './Germany2Users';
import Germany3Users from './Germany3Users';
import FinlandUsers from './FinlandUsers';
import FinlandUsers2 from './FinlandUsers2';
import FranceUsers3 from './FranceUsers3';
import UnitedKingdomUsers from './UnitedKingdomUsers';
import { Users, Filter, Search } from 'react-feather'

const UsersSection = (props) => {

    const [fr, viewFR] = useState(true);
    const [fr2, viewFR2] = useState(true);
    const [fr3, viewFr3] = useState(true);
    const [de, viewDE] = useState(true);
    const [de2, viewDE2] = useState(true);
    const [de3, viewDE3] = useState(true);
    const [nl, viewNL] = useState(true);
    const [pl, viewPL] = useState(true);
    const [fi, viewFI] = useState(true);
    const [fi2, viewFI2] = useState(true);
    const [uk, viewUk] = useState(true);

    const frView = () => {
        if (fr) {
            viewFR(false) ;
            document.getElementById('france').classList.add('hidden');
        } else {
            viewFR(true);
            document.getElementById('france').classList.remove('hidden');
        }
    };
    const fr2View = () => {
        if (fr2) {
            viewFR2(false) ;
            document.getElementById('france2').classList.add('hidden');
        } else {
            viewFR2(true);
            document.getElementById('france2').classList.remove('hidden');
        }
    };
    const deView = () => {
        if (de) {
            viewDE(false) ;
            document.getElementById('germany').classList.add('hidden');
        } else {
            viewDE(true);
            document.getElementById('germany').classList.remove('hidden');
        }
    };
    const de2View = () => {
        if (de2) {
            viewDE2(false) ;
            document.getElementById('germany2').classList.add('hidden');
        } else {
            viewDE2(true);
            document.getElementById('germany2').classList.remove('hidden');
        }
    };
    const de3View = () => {
        if (de3) {
            viewDE3(false) ;
            document.getElementById('germany3').classList.add('hidden');
        } else {
            viewDE3(true);
            document.getElementById('germany3').classList.remove('hidden');
        }
    };
    const nlView = () => {
        if (nl) {
            viewNL(false) ;
            document.getElementById('netherlands').classList.add('hidden');
        } else {
            viewNL(true);
            document.getElementById('netherlands').classList.remove('hidden');
        }
    };
    const plView = () => {
        if (pl) {
            viewPL(false) ;
            document.getElementById('poland').classList.add('hidden');
        } else {
            viewPL(true);
            document.getElementById('poland').classList.remove('hidden');
        }
    };
    const fiView = () => {
        if (fi) {
            viewFI(false) ;
            document.getElementById('finland').classList.add('hidden');
        } else {
            viewFI(true);
            document.getElementById('finland').classList.remove('hidden');
        }
    };
    const fi2View = () => {
        if (fi2) {
            viewFI2(false) ;
            document.getElementById('finland2').classList.add('hidden');
        } else {
            viewFI2(true);
            document.getElementById('finland2').classList.remove('hidden');
        }
    };
    const fr3View = () => {
        if (fr3) {
            viewFr3(false) ;
            document.getElementById('france3').classList.add('hidden');
        } else {
            viewFr3(true);
            document.getElementById('france3').classList.remove('hidden');
        }
    };
    const ukView = () => {
        if (uk) {
            viewUk(false) ;
            document.getElementById('england').classList.add('hidden');
        } else {
            viewUk(true);
            document.getElementById('england').classList.remove('hidden');
        }
    };
    
    const filterUsers = (value) => {
        const rows = Array.from(document.querySelectorAll('.userRow'));
        rows.map(row => 
          !row.id.includes(value.target.value) ? row.classList.add('hidden')
          : row.classList.remove('hidden')
        );
        value.preventDefault();
    };
   
    const changeFilter = (status) => {    
        const expired = document.querySelector('#expired');
        const soon = document.querySelector('#soon');
        const all = document.querySelector('#all');
        const rows = Array.from(document.querySelectorAll('.userRow'));
        if ((status) === "expired") {
          rows.map(row => 
            row.attributes['userstatus'].value !== "0" ? row.classList.add('hidden')
            : row.classList.remove('hidden')         
            );
            expired.classList.remove('border-gray-100');
            expired.classList.add('border-green-500');
            all.classList.remove('border-green-500');
            all.classList.add('border-gray-100');
            soon.classList.remove('border-green-500');
            soon.classList.add('border-gray-100');
        } else if ((status) === "soon") {
          rows.map(row => 
            (row.attributes['userstatus'].value !== "0" && row.attributes['expirytime'].value < 3 && row.attributes['expirytime'].value > 0) || (row.attributes['userstatus'].value !== "0" && row.attributes['totaltraffic'].value > 95.0 && row.attributes['totaltraffic'].value !== "Infinity") ? row.classList.remove('hidden')
            : row.classList.add('hidden') 
          );
          soon.classList.remove('border-gray-100');
          soon.classList.add('border-green-500');
          all.classList.remove('border-green-500');
          all.classList.add('border-gray-100');
            expired.classList.remove('border-green-500');
            expired.classList.add('border-gray-100');
        } else {
          rows.map(row => 
            row.classList.remove('hidden')    
            );
            soon.classList.add('border-gray-100');
            soon.classList.remove('border-green-500');
            expired.classList.add('border-gray-100');
            expired.classList.remove('border-green-500');
            all.classList.remove('border-gray-100');  
            all.classList.add('border-green-500');
        }

    };
  return (
    <div className='mx-5 bg-white rounded border'>
        <div className='flex md:flex-row flex-col justify-between items-center border-b p-5'>
            <div className='flex md:flex-row flex-col items-center md:justify-start justify-center md:w-1/4'>
                <Users size={24} className="ml-2" />
                <h2>
                    کاربران
                </h2>
            </div>
            <div className='md:w-3/4'>
                <div className='flex md:flex-row flex-col items-center md:justify-end justify-center mb-2'>
                    <div className="flex flex-row border rounded md:ml-3 items-center h-8 justify-center w-full mb-2 md:mb-0">
                        <Search size={16} className="text-gray-400 ml-1" />
                        <input onChange={value => filterUsers(value)} type="text" className="w-full focus:outline-none font-sm w-full" placeholder='جستجوی کاربران (حساس به حروف بزرگ و کوچک)' />
                    </div>
                    <div className="flex flex-row items-center justify-center text-center">
                        <Filter size={16} className="text-gray-400 ml-2" />
                        <div onClick={() => changeFilter('expired')} id="expired" className='flex flex-row font-sm border border-gray-100 rounded p-1 h-8 items-center flex w-20 justify-center animate-fade animate-speed-100 cursor-pointer'>منقضی شده</div>
                        <div onClick={() => changeFilter('soon')} id="soon" className='flex flex-row font-sm border border-gray-100 rounded p-1 mx-1 h-8 items-center flex w-20 justify-center animate-fade cursor-pointer'>در حال انقضا</div>
                        <div onClick={() => changeFilter('all')} id="all" className='flex flex-row font-sm border border-gray-100 rounded p-1 h-8 items-center flex w-20 justify-center animate-fade cursor-pointer'>بدون فیلتر</div>
                    </div>
                </div>
                <div className='flex flex-row md:justify-between justify-between items-center rounded border-collapse flex-wrap md:flex-nowrap space-x-1'>
                    <div onClick={frView} style={fr ? {backgroundColor: "#00800030", border: "1px solid #008000"} : {backgroundColor: "#f1f1f130", border: "1px solid #acacac"}} className='font-sm flex flex-col justify-center items-center border-l basis-1/4 border-collapse md:mx-1 mb-1 rounded cursor-pointer'>
                        🇫🇷
                        <span>فرانسه</span>
                    </div>
                    <div onClick={fr2View} style={fr2 ? {backgroundColor: "#00800030", border: "1px solid #008000"} : {backgroundColor: "#f1f1f130", border: "1px solid #acacac"}} className='font-sm flex flex-col justify-center items-center border-l basis-1/4 border-collapse md:mx-1 mb-1 rounded cursor-pointer'>
                        🇫🇷
                        <span>فرانسه 2</span>
                    </div>
                    <div onClick={fr3View} style={fr3 ? {backgroundColor: "#00800030", border: "1px solid #008000"} : {backgroundColor: "#f1f1f130", border: "1px solid #acacac"}} className='font-sm flex flex-col justify-center items-center border-l basis-1/4 border-collapse md:mx-1 mb-1 rounded cursor-pointer text-center'>
                        🇫🇷
                        <span>فرانسه 3</span>
                    </div>
                    <div onClick={deView} style={de ? {backgroundColor: "#00800030", border: "1px solid #008000"} : {backgroundColor: "#f1f1f130", border: "1px solid #acacac"}} className='font-sm flex flex-col justify-center items-center border-l basis-1/4 border-collapse md:mx-1 mb-1 rounded cursor-pointer'>
                        🇩🇪
                        <span>آلمان</span>
                    </div>
                    <div onClick={de2View} style={de2 ? {backgroundColor: "#00800030", border: "1px solid #008000"} : {backgroundColor: "#f1f1f130", border: "1px solid #acacac"}} className='font-sm flex flex-col justify-center items-center border-l basis-1/4 border-collapse md:mx-1 mb-1 rounded cursor-pointer'>
                        🇩🇪
                        <span>آلمان 2</span>
                    </div>
                    <div onClick={de3View} style={de3 ? {backgroundColor: "#00800030", border: "1px solid #008000"} : {backgroundColor: "#f1f1f130", border: "1px solid #acacac"}} className='font-sm flex flex-col justify-center items-center border-l basis-1/4 border-collapse md:mx-1 mb-1 rounded cursor-pointer'>
                        🇩🇪
                        <span>آلمان 3</span>
                    </div>
                    <div onClick={fiView} style={fi ? {backgroundColor: "#00800030", border: "1px solid #008000"} : {backgroundColor: "#f1f1f130", border: "1px solid #acacac"}} className='font-sm flex flex-col justify-center items-center border-l basis-1/4 border-collapse md:mx-1 mb-1 rounded cursor-pointer'>
                        🇫🇮
                        <span>فنلاند</span>
                    </div>
                    <div onClick={fi2View} style={fi2 ? {backgroundColor: "#00800030", border: "1px solid #008000"} : {backgroundColor: "#f1f1f130", border: "1px solid #acacac"}} className='font-sm flex flex-col justify-center items-center border-l basis-1/4 border-collapse md:mx-1 mb-1 rounded cursor-pointer'>
                        🇫🇮
                        <span>فنلاند 2</span>
                    </div>
                    <div onClick={nlView} style={nl ? {backgroundColor: "#00800030", border: "1px solid #008000"} : {backgroundColor: "#f1f1f130", border: "1px solid #acacac"}} className='font-sm flex flex-col justify-center items-center border-l basis-1/4 border-collapse md:mx-1 mb-1 rounded cursor-pointer'>
                        🇳🇱
                        <span>هلند</span>
                    </div>
                    <div onClick={plView} style={pl ? {backgroundColor: "#00800030", border: "1px solid #008000"} : {backgroundColor: "#f1f1f130", border: "1px solid #acacac"}} className='font-sm flex flex-col justify-center items-center border-l basis-1/4 border-collapse md:mx-1 mb-1 rounded cursor-pointer'>
                        🇵🇱
                        <span>لهستان</span>
                    </div>
                    <div onClick={ukView} style={uk ? {backgroundColor: "#00800030", border: "1px solid #008000"} : {backgroundColor: "#f1f1f130", border: "1px solid #acacac"}} className='font-sm flex flex-col justify-center items-center border-l basis-1/4 border-collapse md:mx-1 mb-1 rounded cursor-pointer'>
                        🇬🇧
                        <span>انگلستان</span>
                    </div>
                </div>
            </div>
        </div>
        <div id="france">
            <FranceUsers token={props.token} credit={props.credit} prefix={props.prefix} frCount={props.frCount} />
        </div>
{/*         <div id="france2">
            <FranceUsers2 token={props.token} credit={props.credit} prefix={props.prefix} fr2Count={props.fr2Count} />
        </div>
        <div id="france3">
            <FranceUsers3 token={props.token} credit={props.credit} prefix={props.prefix} fr3Count={props.fr3Count} />
        </div>
         <div id="germany">
            <GermanyUsers token={props.token} credit={props.credit} prefix={props.prefix} deCount={props.deCount} />
        </div>
        <div id="germany2">
            <Germany2Users token={props.token} credit={props.credit} prefix={props.prefix} de2Count={props.de2Count} />
        </div>
        <div id="germany3">
            <Germany3Users token={props.token} credit={props.credit} prefix={props.prefix} de3Count={props.de3Count} />
        </div>
        <div id="finland">
            <FinlandUsers token={props.token} credit={props.credit} prefix={props.prefix} fiCount={props.fiCount} />
        </div>
        <div id="finland2">
            <FinlandUsers2 token={props.token} credit={props.credit} prefix={props.prefix} fi2Count={props.fi2Count} />
        </div>
        <div id="netherlands">
            <NetherlandsUsers token={props.token} credit={props.credit} prefix={props.prefix} nlCount={props.nlCount} />
        </div>
        <div id="poland">
            <PolandUsers token={props.token} credit={props.credit} prefix={props.prefix} plCount={props.plCount} />
        </div>
        <div id="england">
            <UnitedKingdomUsers token={props.token} credit={props.credit} prefix={props.prefix} ukCount={props.ukCount} />
        </div> */}
    </div>
  )
}

export default UsersSection
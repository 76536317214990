import React from 'react'
import { Terminal } from 'react-feather'

const Footer = () => {
  return (
    <div className=' px-5 pt-3 pb-3'>
      <div className='lg:mx-20 lg:px-20'>
        <div className='flex flex-row items-center justify-between'>
          <div className='flex flex-col items-start justify-start text-right'>
            <div className='font-sm'>
              &copy; کلیه حقوق برای تیم FirstVPN محفوظ است.
            </div>
            <div className='font-sm'>
              2023-1401
              |
              نسخه 1.1.3
            </div>
          </div>
          <div className='font-sm flex flex-row items-center justify-end text-left'>
            <div>
            <strong>بـــرای</strong>
            <p>
            دسترسی به اینترنت آزاد
            </p>
            </div>
            <Terminal size="32" className='mr-1 bg-blue-100 p-1 rounded text-blue-500' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import moment from 'jalali-moment';
import axios from 'axios';
import {QRCodeCanvas, QRCodeSVG} from 'qrcode.react';
import Config from '../Config.json';
import { User, Loader, AlertTriangle, Copy, RefreshCw, Frown } from 'react-feather'
import {CopyToClipboard} from 'react-copy-to-clipboard';
const base64json = require('base64json');



const FranceUsers = (props) => {

    
    const paginationComponentOptions = {
        rowsPerPageText: 'نتیجه در صفحه',
        rangeSeparatorText: 'از',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'همه',
    };
    const columns = [
      {
        name: 'وضعیت',
        selector: row => 
          row.enable ?
          <div className='justify-center items-center fw-bold rounded p-2 flex flex-row bg-green-100 text-green-400 font-sm'>
              <div className='animate-ping w-1 h-1 bg-green-500 rounded-full mx-1'></div>
          </div>
          :
          <div className='justify-center items-center fw-bold rounded p-2 flex flex-row bg-red-100 text-red-400 font-sm'>
              <div className='animate-ping w-1 h-1 bg-red-500 rounded-full mx-1'></div>
          </div>
      },
      {
        name: 'کاربر',
        selector: row => row.id + " - " + row.remark,
      }
    ];
    const ExpandedComponent = ({ data }) => 
    <div className='flex flex-row w-100 flex-nowrap justify-center items-center p-10 bg-gray-50'>
      
    </div>;

    const [users, setUsers] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [updateLoading, setUpdateLoading] = useState(false);
    const [updateError, setUpdateError] = useState(null);
    const [updateComplete, setUpdateComplete] = useState(null);
    const reviseUser = async (userid, userRemark, userPort, userUUID, userServer) => {
        setUpdateLoading(true);
        const body = {
            id: userid,
            remark: userRemark,
            port: userPort,
            uuid: userUUID 
        };
        const headers = {
          headers: {
              "Content-Type": "application/json",
              "token": `${props.token}`
            }
        };
        await axios.post(`${Config.API_URL}/${userServer.split('.')[0]}/revise`, body, headers)
        .then(res => {
            setUpdateLoading(false);
            setUpdateComplete(true);
            setTimeout(setUpdateComplete(false), 2500);
        })
        .catch(err => {
            setUpdateLoading(false);
            setUpdateError('خطا در بروزرسانی');
        });
    };
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [deleteError, setDeleteError] = useState(null);
    const [deleteComplete, setDeleteComplete] = useState(null);
    const deleteUser = async (userid, userRemark, userPort, userServer) => {
        setDeleteLoading(true);
        const body = {
            id: userid,
            remark: userRemark,
            port: userPort
        };
        const headers = {
          headers: {
            "Content-Type": "application/json",
            "token": `${props.token}`
          }
        };
        await axios.post(`${Config.API_URL}/${userServer.split('.')[0]}/delete`, body, headers)
          .then(res => {
            setDeleteLoading(false);
            setDeleteComplete(true);
            setTimeout(setDeleteComplete(false), 2500);
          })
          .catch(err => {
            setUpdateLoading(false);
            setDeleteError('خطا در حذف');
          });
      };

    useEffect(() => {
        const headers = {
            headers: {
                "Content-Type": "application/json",
                "token": `${props.token}`
            }
        };
        try {
            axios.post(`${Config.API_URL}/fr/userslist`, {}, headers)
            .then(res => {
                setUsers(res.data.users);
                //props.frCount(res.data.users.length)
                setLoading(false);
                setError(null);
            });
        } catch (err) {
            setUsers(null);
            setLoading(false);
            setError(true);
        }
    },[props, users]);
  return (
    <>
    { users ?
    <div className='ltr mt-1 w-100' dir="ltr">
        <DataTable
            className='ltr'
            dir="ltr"
            columns={columns}
            data={users}
            expandableRows
            expandableRowsComponent={ExpandedComponent}
            pagination
            paginationComponentOptions={paginationComponentOptions}
        />
    </div>
    : ""}
    </>
  )
}

export default FranceUsers
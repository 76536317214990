import React from 'react'
import { useState } from 'react';
import axios from 'axios';
import Config from '../Config.json';
import localStorage from 'localStorage';


const Login = (props) => {

  const [loginError, setLoginError] = useState(false);
  const [loading, setLoading] = useState(false);
  const headers = {
    headers: {
        "Content-Type": "application/json"
    }
  };

  const loginUser = async () => {
    const username =  document.querySelector('#username').value;
    const password =  document.querySelector('#password').value;
    if (!username) {
      document.querySelector('#username').classList.add('border-red-500');
      setLoginError(true);
    } else {
      document.querySelector('#username').classList.remove('border-red-500');
      setLoginError(false);
    }

    if (!password) {
      document.querySelector('#password').classList.add('border-red-500');
      setLoginError(true);
    } else {
      document.querySelector('#password').classList.remove('border-red-500');
      setLoginError(false);
    }

    if (username && password) {
      setLoginError(false);
      setLoading(true);
       await axios.post(`${Config.API_URL}/login`, {username: username, password: password}, headers)
       .then (result => {
        setLoading(false);
        localStorage.setItem('username', username);
        props.login(result.data.token)
       })
       .catch(err => {
        setLoading(false);
        setLoginError(true);
       });
    } else {
      setLoading(false);
      setLoginError(true);
    }
  };
  return (
    <div className='h-screen justify-center items-center flex bg-white flex flex-col'>
      <img className='rounded-full h-24 w-24 mx-auto p-3' src={require('../images/logo.jpg')} alt="FirstVPN" />
      <div className='rounded p-5 border shadow shadow-sm flex flex-col bg-white flex-0 flex-basis-1 w-100'>
        {
          loginError ? 
          <div className='border rounded p-1 text-center my-1 mx-5 w-100 bg-red-100 text-red-500 border-red-300 fw-bold flex flex-col'>
            <div>
              خطا
            </div>
          </div>
          :
          ""
        }
        <div className=''>
          <input id="username" className='text-center border rounded p-1 text-center my-1 mx-5 w-100' type="text" placeholder='نام کاربری' />
        </div>
        <div className=''>
          <input id="password" className='text-center border rounded p-1 text-center my-1 mx-5 w-100' type="password" placeholder='رمز عبور' />
        </div>
        {
          loading ?
            <button type="button" className='bg-gray-200 rounded p-1 text-gray-600 mx-5 my-1 w-100 text-center cursor-not-allowed' disabled>در حال ورود...</button>
            :
            <button onClick={loginUser} className='bg-blue-500 rounded p-1 text-white mx-5 my-1 w-100 text-center'>ورود</button>
          }
      </div>
    </div>
  )
}

export default Login
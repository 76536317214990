import {  BrowserRouter,  Routes,  Route } from "react-router-dom";
import { useState } from "react";
import './index.css'
import localStorage from 'localStorage';
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Dashboard from "./Pages/Dashboard";
import Login from "./Pages/Login";
import CreateUser from "./Pages/CreateUser";
import Servers from "./Pages/Servers";
import CheckUser from "./Pages/CheckUser";
import AddCredit from "./Pages/AddCredit";

function App() {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const changeLogin = (value) => {
      localStorage.setItem('token', value)
      setToken(value)
  };
  const removeToken = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('frCount');
    localStorage.removeItem('deCount');
    localStorage.removeItem('usCount');
    localStorage.removeItem('nlCount');
    localStorage.removeItem('fiCount');
    localStorage.removeItem('fr2Count');
    localStorage.removeItem('de2Count');
    localStorage.removeItem('us2Count');
    localStorage.removeItem('nl2Count');
    localStorage.removeItem('fi2Count');
    localStorage.removeItem('prefix');
    localStorage.clear();
    localStorage.clear();
    setToken(null);
  };
  return (
    <div className='bg-gray-50 h-screen'>
      {token ?
      <BrowserRouter>
        <>
          <Header token={token} logout={removeToken} />
          <main>
              <Routes>
                <Route index element={<Dashboard token={token} title="داشبورد" />} />
                <Route path="/" element={<Dashboard token={token} title="داشبورد" />} />
                <Route path="/Create" element={<CreateUser token={token} title="کاربر" />} />
                <Route path="/Servers" element={<Servers token={token} title="سرورها" />} />
                <Route path="/Users" element={<CheckUser token={token} title="وضعیت" />} />
                <Route path="/AddCredit" element={<AddCredit token={token} title="اعتبار" />} />
              </Routes>
          </main>
          <Footer />
        </>
      </BrowserRouter>
      :
      <Login login={changeLogin} />
      }
    </div>
  );
}

export default App;

import React from 'react'
import { LogOut, User } from 'react-feather'
import localStorage from 'localStorage';

const Users = (props) => {
  const username = localStorage.getItem('username');
  return (
    <div className='flex flex-row items-center justify-start'>
      <div className='bg-white rounded-full border h-16 w-16 flex flex-row items-center justify-center'>
          <User size={48} className='text-gray-600' />
      </div>
      <div className="mr-1 fw-bold">
        {
          username ? username : "..."
        }
      </div>
      <LogOut size={16} className="mx-3 text-red-500" onClick={props.logout} />
    </div>
  )
}

export default Users
import React, { useState, useEffect } from 'react'
import { UserPlus, ArrowRight, Info, Copy, Loader, ArrowLeft, ArrowUp, ArrowDown, AlertTriangle, Paperclip, MapPin, Link2, BarChart, Calendar, User, Download } from 'react-feather'
import { Link } from "react-router-dom";
import axios from 'axios';
import Config from '../Config.json';
import moment from 'jalali-moment';
import QRCode from "react-qr-code";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import * as htmlToImage from 'html-to-image';
import { FileSaver } from 'file-saver';

const CreateUser = (props) => {
  const frCapacity = 250;
  const fr2Capacity = 250;
  const fr3Capacity = 250;
  const deCapacity = 250;
  const de2Capacity = 250;
  const de3Capacity = 250;
  const fiCapacity = 250;
  const fi2Capacity = 250;
  const nlCapacity = 100;
  const plCapacity = 125;
  const ukCapacity = 2;
  
  const [createError, setCreateError] = useState(null);
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "token": `${props.token}`
    }
  };

  const handleClickScroll = () => {
    const element = document.getElementById('resultsSection');
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const saveImage = () => {
    htmlToImage.toPng(document.getElementById('resultsSection'))
    .then(function (dataUrl) {
      if (window.saveAs) {
        window.saveAs(dataUrl, data.id + " - " + data.remark + " - " + data.port);
      } else {
        FileSaver.saveAs(dataUrl, data.id + " - " + data.remark + " - " + data.port);
     }
    });
  };

  const [value, setValue] = useState(null);
  const [loading, isLoading] = useState(false);
  
  const [credit, setCredit] = useState(null);
  const [prefix, setPrefix] = useState(null);
  const [loadingCredit, setLoadingCredit] = useState(true);


  const getCredit = async () => {
      await axios.get(`${Config.API_URL}/reseller`, headers)
      .then(res => {
          setCredit(res.data.credit);
          setPrefix(res.data.prefix);
          setLoadingCredit(false);
      })
      .catch(err => {
        setCredit(0);
        setLoadingCredit(false)
      });
  };

  const [fiInbounds, fiInboundsSetter] = useState(null);
  const [fiError, fiErrorSetter] = useState(false);
  const [fiLoading, fiLoadingSetter] = useState(true);
  const getInboundsCountFI = async () => {
    await axios.get(`${Config.API_URL}/fi/inbounds`, headers)
    .then(res => {
      console.log(res);
      fiInboundsSetter(res.data.count);
      fiLoadingSetter(false)
    })
    .catch(err => {
      console.log(err);
      fiErrorSetter("خطا!")
      fiLoadingSetter(false)
    });
  };
  const [fi2Inbounds, fi2InboundsSetter] = useState(null);
  const [fi2Error, fi2ErrorSetter] = useState(false);
  const [fi2Loading, fi2LoadingSetter] = useState(true);
  const getInboundsCountFI2 = async () => {
    await axios.get(`${Config.API_URL}/fi2/inbounds`, headers)
    .then(res => {
      console.log(res);
      fi2InboundsSetter(res.data.count);
      fi2LoadingSetter(false)
    })
    .catch(err => {
      console.log(err);
      fi2ErrorSetter("خطا!")
      fi2LoadingSetter(false)
    });
  };

  const [nlInbounds, nlInboundsSetter] = useState(null);
  const [nlError, nlErrorSetter] = useState(false);
  const [nlLoading, nlLoadingSetter] = useState(true);
  const getInboundsCountNL = async () => {
    await axios.get(`${Config.API_URL}/nl/inbounds`, headers)
    .then(res => {
      nlInboundsSetter(res.data.count);
      nlLoadingSetter(false);
    })
    .catch(err => {
      nlErrorSetter("خطا!")
      nlLoadingSetter(false);
    });
  };
  const [plInbounds, plInboundsSetter] = useState(null);
  const [plError, plErrorSetter] = useState(false);
  const [plLoading, plLoadingSetter] = useState(true);
  const getInboundsCountPL = async () => {
    await axios.get(`${Config.API_URL}/pl/inbounds`, headers)
    .then(res => {
      plInboundsSetter(res.data.count);
      plLoadingSetter(false);
    })
    .catch(err => {
      plErrorSetter("خطا!")
      plLoadingSetter(false);
    });
  };

  const [deInbounds, deInboundsSetter] = useState(null);
  const [deError, deErrorSetter] = useState(false);
  const [deLoading, deLoadingSetter] = useState(true);
  const getInboundsCountDE = async () => {
    await axios.get(`${Config.API_URL}/de/inbounds`, headers)
    .then(res => {
      deInboundsSetter(res.data.count);
      deLoadingSetter(false);
    })
    .catch(err => {
      deErrorSetter("خطا!");
      deLoadingSetter(false);
    });
  };
  const [de2Inbounds, de2InboundsSetter] = useState(null);
  const [de2Error, de2ErrorSetter] = useState(false);
  const [de2Loading, de2LoadingSetter] = useState(true);
  const getInboundsCountDE2 = async () => {
    await axios.get(`${Config.API_URL}/de2/inbounds`, headers)
    .then(res => {
      de2InboundsSetter(res.data.count);
      de2LoadingSetter(false);
    })
    .catch(err => {
      de2ErrorSetter("خطا!");
      de2LoadingSetter(false);
    });
  };

  const [de3Inbounds, de3InboundsSetter] = useState(null);
  const [de3Error, de3ErrorSetter] = useState(false);
  const [de3Loading, de3LoadingSetter] = useState(true);
  const getInboundsCountDE3 = async () => {
    await axios.get(`${Config.API_URL}/de3/inbounds`, headers)
    .then(res => {
      de3InboundsSetter(res.data.count);
      de3LoadingSetter(false);
    })
    .catch(err => {
      de3ErrorSetter("خطا!");
      de3LoadingSetter(false);
    });
  };

  const [frInbounds, frInboundsSetter] = useState(null);
  const [frError, frErrorSetter] = useState(false);
  const [frLoading, frLoadingSetter] = useState(true);
  const getInboundsCountFR = async () => {
    await axios.get(`${Config.API_URL}/fr/inbounds`, headers)
    .then(res => {
      frInboundsSetter(res.data.count);
      frLoadingSetter(false);
    })
    .catch(err => {
      frErrorSetter("خطا!");
      frLoadingSetter(false);
    });
  };

  const [fr2Inbounds, fr2InboundsSetter] = useState(null);
  const [fr2Error, fr2ErrorSetter] = useState(false);
  const [fr2Loading, fr2LoadingSetter] = useState(true);
  const getInboundsCountFR2 = async () => {
    await axios.get(`${Config.API_URL}/fr2/inbounds`, headers)
    .then(res => {
      fr2InboundsSetter(res.data.count);
      fr2LoadingSetter(false);
    })
    .catch(err => {
      fr2ErrorSetter("خطا!");
      fr2LoadingSetter(false);
    });
  };

  const [fr3Inbounds, fr3InboundsSetter] = useState(null);
  const [fr3Error, fr3ErrorSetter] = useState(false);
  const [fr3Loading, fr3LoadingSetter] = useState(true);
  const getInboundsCountFr3 = async () => {
    await axios.get(`${Config.API_URL}/fr3/inbounds`, headers)
    .then(res => {
      fr3InboundsSetter(res.data.count);
      fr3LoadingSetter(false);
    })
    .catch(err => {
      fr3ErrorSetter("خطا!");
      fr3LoadingSetter(false);
    });
  };

  const [ukInbounds, ukInboundsSetter] = useState(null);
  const [ukError, ukErrorSetter] = useState(false);
  const [ukLoading, ukLoadingSetter] = useState(true);
  const getInboundsCountUk = async () => {
    await axios.get(`${Config.API_URL}/uk/inbounds`, headers)
    .then(res => {
      ukInboundsSetter(res.data.count);
      ukLoadingSetter(false);
    })
    .catch(err => {
      ukErrorSetter("خطا!");
      ukLoadingSetter(false);
    });
  };
    
  useEffect(() => {
    getInboundsCountFR();
    getInboundsCountFR2();
    getInboundsCountFr3();
    getInboundsCountDE();
    getInboundsCountDE2();
    getInboundsCountDE3();
    getInboundsCountFI();
    getInboundsCountFI2();
    getInboundsCountNL();
    getInboundsCountPL();
    getInboundsCountUk();
    getCredit();
  }, []);

  const [selectedServer, setSelectedServer] = useState(null);

  const [data, setUser] = useState(null);
  const createUser = async (event) => {
    event.preventDefault();

    const value = prefix + event.target.uri.value;

    setUser(null);
    setCreateError(null);
    try {
      if (value && selectedServer) {
        isLoading(true);
        await axios.post(`${Config.API_URL}/${selectedServer}/add`, {remark: value}, headers)
        .then(res => {
          isLoading(false);
          setUser(res.data);
          setValue(null);
          setSelectedServer(null);
          event.target.reset();
          handleClickScroll();
        })
        .catch(err => {
          setValue(null);
          setSelectedServer(null);
          isLoading(false);
          setCreateError("ایجاد کاربر با خطا مواجه شد!");
          event.target.reset();
        });
      } else {
        setValue(null);
        setSelectedServer(null);
        setCreateError("صحت ورودی های خود را کنترل کنید!");
        event.target.reset();
      }
    } catch {
      setValue(null);
      setSelectedServer(null);
      setCreateError("خطا در ارتباط با سرور!");
      event.target.reset();
    }
  };
  return (
    <div className='lg:mx-20 lg:px-20'>
      <div className='flex flex-row bg-blue-700 text-white px-5 items-center justify-between py-12 rounded-b'>
        <h1 className=''>{props.title}</h1>
        <Link to="/">
          <button className='flex flex-row items-center justify-center bg-white text-blue-700 pl-2 pt-1 pb-1 pr-2 rounded border border-blue-500 transition hover:bg-blue-700 hover:text-white hover:border hover:border-white hover:transition'>
            <ArrowRight width="16" height="16" className='mx-1' /> برگشت
          </button>
        </Link>
      </div>
      <div className='p-5 flex flex-row justify-between items-center mt-5 md:-mt-12 mb-0 w-full flex-wrap md:flex-nowrap'>
        <div className='flex flex-col items-stretch justify-center w-full bg-white rounded flex-column p-5 border shadow-md mb-5 sm:flex-basis-1 md:flex-basis-1/3 hover:shadow-none transition ease-linear duration-300 hover:duration:300 hover:transition hover:ease-linear'>
          <div className='flex flex-row justify-between items-center w-100'>
            <h2>ایجاد کاربر جدید</h2>
            <UserPlus className='bg-blue-100 text-blue-700 p-1 rounded' width="32" height="32" />
          </div>
          <div className='flex flex-row text-justify justify-start items-center bg-gray-100 p-3 mt-3 mb-5 rounded text-gray-600 text-sm'>
            <Info className='w-40 md:w-10 ml-2' /> برای کاربر خود یک نام یکتا انتخاب کنید، در فیلد زیر وارد کرده و سرور مورد نظر را انتخاب نمائید. در پایان با فشردن دکمه تایید، کاربر شما ایجاد خواهد شد. توجه داشته باشید که ظرفیت هر سرور را پیش از انتخاب بررسی نمایید. همچنین در صورتی که اعتبار کافی برای ایجاد کاربر را نداشته باشید با خطا مواجه خواهید شد. لازم بذکر است در صورت انتخاب نام تکراری با خطا مواجه خواهید شد.
          </div>
          <div id="serversList">
            {
              loadingCredit ?
              <div className='flex flex-col items-center justify-between text-gray-600 mt-5 bg-gray-100 rounded p-5'>
                <Loader className='animate-spin animate-speed-100 text-gray-400' size={48} /> 
                <div className=' text-gray-400 mt-3'>
                  در حال دریافت اطلاعات
                </div>
              </div>
              : credit && credit > 0 ?
              <>
              <div  className='flex flex-col md:flex-row items-center justify-between text-gray-600 mt-5 md:flex-wrap flex-nowrap space-x-1 space-y-1'>
              {
                frLoading ?
                <>
                <div className='flex flex-col justify-center items-center border rounded p-5 w-full flex-basis-1 md:basis-1/4'>
                  <div>🇫🇷</div>
                  <div className='fw-bold'>فرانسه</div>
                  <div className='fw-bold'>
                    <Loader className='animate-spin animate-speed-100 text-gray-400' size={52} /> 
                  </div>
                </div>
                </>
                : frInbounds && !frError ?
                <div onClick={() => setSelectedServer('fr')} style={ selectedServer && selectedServer === "fr" ? {border: "1px solid #0db653ff", backgroundColor: "#0db65315"} : {border: "1px solid rgb(229, 231, 235)"} } className='flex flex-col justify-center items-center border rounded p-5 w-full flex-basis-1 md:basis-1/4'>
                  <div>🇫🇷</div>
                  <div className='fw-bold'>فرانسه</div>
                  {/* <div className='fw-bold'>
                    <span className={ frInbounds > frCapacity ? "text-red-500" : "text-green-500" }>
                      {((frInbounds * 100) / frCapacity).toFixed(0) + "%"}
                    </span>
                  </div> */}
                  <div className='font-sm'>
                    یکماهه - 50GB
                  </div>
                  <div className='font-sm'>
                    مناسب اینترنت همراه و خانگی
                  </div>
                </div>
                : 
                <div className='flex flex-col justify-center items-center border rounded p-5 w-full flex-basis-1 md:basis-1/4'>
                  <div>🇫🇷</div>
                  <div className='fw-bold'>فرانسه</div>
                  <div className='fw-bold text-red-500 flex flex-col items-center justify-center text-center'>
                    <AlertTriangle size={32} className='' />
                    خطا در دریافت اطلاعات
                  </div>
                </div>
              }
              {
                fr2Loading ?
                <>
                <div className='flex flex-col justify-center items-center border rounded p-5 w-full flex-basis-1 md:basis-1/4'>
                  <div>🇫🇷</div>
                  <div className='fw-bold'>فرانسه 2</div>
                  <div className='fw-bold'>
                    <Loader className='animate-spin animate-speed-100 text-gray-400' size={52} /> 
                  </div>
                </div>
                </>
                : fr2Inbounds && !fr2Error ?
                <div onClick={() => setSelectedServer('fr2')} style={ selectedServer && selectedServer === "fr2" ? {border: "1px solid #0db653ff", backgroundColor: "#0db65315"} : {border: "1px solid rgb(229, 231, 235)"} } className='flex flex-col justify-center items-center border rounded p-5 w-full flex-basis-1 md:basis-1/4'>
                  <div>🇫🇷</div>
                  <div className='fw-bold'>فرانسه 2</div>
                  {/* <div className='fw-bold'>
                    <span className={ fr2Inbounds > fr2Capacity ? "text-red-500" : "text-green-500" }>
                      {((fr2Inbounds * 100) / fr2Capacity).toFixed(0) + "%"}
                    </span>
                  </div> */}
                  <div className='font-sm'>
                    یکماهه - 50GB
                  </div>
                  <div className='font-sm'>
                    مناسب اینترنت همراه و خانگی
                  </div>
                </div>
                : 
                <div className='flex flex-col justify-center items-center border rounded p-5 w-full flex-basis-1 md:basis-1/4'>
                  <div>🇫🇷</div>
                  <div className='fw-bold'>فرانسه 2</div>
                  <div className='fw-bold text-red-500 flex flex-col items-center justify-center text-center'>
                    <AlertTriangle size={32} className='' />
                    خطا در دریافت اطلاعات
                  </div>
                </div>
              }
              {/* {
                fr3Loading ?
                <>
                <div className='flex flex-col justify-center items-center border rounded p-5 w-full flex-basis-1 md:basis-1/4'>
                  <div>🇫🇷</div>
                  <div className='fw-bold'>فرانسه 3</div>
                  <div className='fw-bold'>
                    <Loader className='animate-spin animate-speed-100 text-gray-400' size={52} /> 
                  </div>
                </div>
                </>
                : fr3Inbounds && !fr3Error ?
                <div onClick={() => setSelectedServer('fr3')} style={ selectedServer && selectedServer === "fr3" ? {border: "1px solid #0db653ff", backgroundColor: "#0db65315"} : {border: "1px solid rgb(229, 231, 235)"} } className='flex flex-col justify-center items-center border rounded p-5 w-full flex-basis-1 md:basis-1/4'>
                  <div>🇫🇷</div>
                  <div className='fw-bold'>فرانسه 3</div>
                  <div className='fw-bold'>
                    <span className={ fr3Inbounds > fr3Capacity ? "text-red-500" : "text-green-500" }>
                      {((fr3Inbounds * 100) / fr3Capacity).toFixed(0) + "%"}
                    </span>
                  </div>
                  <div className='font-sm'>
                    یکماهه - 50GB
                  </div>
                  <div className='font-sm'>
                    مناسب اینترنت همراه و خانگی
                  </div>
                </div>
                : 
                <div className='flex flex-col justify-center items-center border rounded p-5 w-full flex-basis-1 md:basis-1/4'>
                  <div>🇫🇷</div>
                  <div className='fw-bold'>فرانسه 3</div>
                  <div className='fw-bold text-red-500 flex flex-col items-center justify-center text-center'>
                    <AlertTriangle size={32} className='' />
                    خطا در دریافت اطلاعات
                  </div>
                </div>
              } */}
              {
                deLoading ?
                <>
                <div className='flex flex-col justify-center items-center border rounded p-5 w-full flex-basis-1 md:basis-1/4'>
                  <div>🇩🇪</div>
                  <div className='fw-bold'>آلمان</div>
                  <div className='fw-bold'>
                    <Loader className='animate-spin animate-speed-100 text-gray-400' size={52} /> 
                  </div>
                </div>
                </>
                : deInbounds && !deError ?
                <div onClick={() => setSelectedServer('de')} style={ selectedServer && selectedServer === "de" ? {border: "1px solid #0db653ff", backgroundColor: "#0db65315"} : {border: "1px solid rgb(229, 231, 235)"} } className='flex flex-col justify-center items-center border rounded p-5 w-full flex-basis-1 md:basis-1/4'>
                  <div>🇩🇪</div>
                  <div className='fw-bold'>آلمان</div>
                  {/* <div className='fw-bold'>
                    <span className={ deInbounds > deCapacity ? "text-red-500" : "text-green-500" }>
                      {((deInbounds * 100) / deCapacity).toFixed(0) + "%"}
                    </span>
                  </div> */}
                  <div className='font-sm'>
                    یکماهه - 50GB
                  </div>
                  <div className='font-sm'>
                    مناسب اینترنت همراه و خانگی
                  </div>
                </div>
                : 
                <div className='flex flex-col justify-center items-center border rounded p-5 w-full flex-basis-1 md:basis-1/4'>
                  <div>🇩🇪</div>
                  <div className='fw-bold'>آلمان</div>
                  <div className='fw-bold text-red-500 flex flex-col items-center justify-center text-center'>
                    <AlertTriangle size={32} className='' />
                    خطا در دریافت اطلاعات
                  </div>
                </div>
              }
              {
                de2Loading ?
                <>
                <div className='flex flex-col justify-center items-center border rounded p-5 w-full flex-basis-1 md:basis-1/4'>
                  <div>🇩🇪</div>
                  <div className='fw-bold'>آلمان 2</div>
                  <div className='fw-bold'>
                    <Loader className='animate-spin animate-speed-100 text-gray-400' size={52} /> 
                  </div>
                </div>
                </>
                : de2Inbounds && !de2Error ?
                <div onClick={() => setSelectedServer('de2')} style={ selectedServer && selectedServer === "de2" ? {border: "1px solid #0db653ff", backgroundColor: "#0db65315"} : {border: "1px solid rgb(229, 231, 235)"} } className='flex flex-col justify-center items-center border rounded p-5 w-full flex-basis-1 md:basis-1/4'>
                  <div>🇩🇪</div>
                  <div className='fw-bold'>آلمان 2</div>
                  {/* <div className='fw-bold'>
                    <span className={ de2Inbounds > de2Capacity ? "text-red-500" : "text-green-500" }>
                      {((de2Inbounds * 100) / de2Capacity).toFixed(0) + "%"}
                    </span>
                  </div> */}
                  <div className='font-sm'>
                    یکماهه - 50GB
                  </div>
                  <div className='font-sm'>
                    مناسب اینترنت همراه و خانگی
                  </div>
                </div>
                : 
                <div className='flex flex-col justify-center items-center border rounded p-5 w-full flex-basis-1 md:basis-1/4'>
                  <div>🇩🇪</div>
                  <div className='fw-bold'>آلمان 2</div>
                  <div className='fw-bold text-red-500 flex flex-col items-center justify-center text-center'>
                    <AlertTriangle size={32} className='' />
                    خطا در دریافت اطلاعات
                  </div>
                </div>
              }
              {/* {
                de3Loading ?
                <>
                <div className='flex flex-col justify-center items-center border rounded p-5 w-full flex-basis-1 md:basis-1/4'>
                  <div>🇩🇪</div>
                  <div className='fw-bold'>آلمان 3</div>
                  <div className='fw-bold'>
                    <Loader className='animate-spin animate-speed-100 text-gray-400' size={52} /> 
                  </div>
                </div>
                </>
                : de3Inbounds && !de3Error ?
                <div onClick={() => setSelectedServer('de3')} style={ selectedServer && selectedServer === "de3" ? {border: "1px solid #0db653ff", backgroundColor: "#0db65315"} : {border: "1px solid rgb(229, 231, 235)"} } className='flex flex-col justify-center items-center border rounded p-5 w-full flex-basis-1 md:basis-1/4'>
                  <div>🇩🇪</div>
                  <div className='fw-bold'>آلمان 3</div>
                  <div className='fw-bold'>
                    <span className={ de3Inbounds > de3Capacity ? "text-red-500" : "text-green-500" }>
                      {((de3Inbounds * 100) / de3Capacity).toFixed(0) + "%"}
                    </span>
                  </div>
                  <div className='font-sm'>
                    یکماهه - 50GB
                  </div>
                  <div className='font-sm'>
                    مناسب اینترنت همراه و خانگی
                  </div>
                </div>
                : 
                <div className='flex flex-col justify-center items-center border rounded p-5 w-full flex-basis-1 md:basis-1/4'>
                  <div>🇩🇪</div>
                  <div className='fw-bold'>آلمان 3</div>
                  <div className='fw-bold text-red-500 flex flex-col items-center justify-center text-center'>
                    <AlertTriangle size={32} className='' />
                    خطا در دریافت اطلاعات
                  </div>
                </div>
              } */}
              {
                fiLoading ?
                <>
                <div className='flex flex-col justify-center items-center border rounded p-5 w-full flex-basis-1 md:basis-1/4'>
                  <div>🇫🇮</div>
                  <div className='fw-bold'>فنلاند</div>
                  <div className='fw-bold'>
                    <Loader className='animate-spin animate-speed-100 text-gray-400' size={52} /> 
                  </div>
                </div>
                </>
                : fiInbounds && !fiError ?
                <div onClick={() => setSelectedServer('fi')} style={ selectedServer && selectedServer === "fi" ? {border: "1px solid #0db653ff", backgroundColor: "#0db65315"} : {border: "1px solid rgb(229, 231, 235)"} } className='flex flex-col justify-center items-center border rounded p-5 w-full flex-basis-1 md:basis-1/4'>
                  <div>🇫🇮</div>
                  <div className='fw-bold'>فنلاند</div>
                  {/* <div className='fw-bold'>
                    <span className={ fiInbounds > fiCapacity ? "text-red-500" : "text-green-500" }>
                      {((fiInbounds * 100) / fiCapacity).toFixed(0) + "%"}
                    </span>
                  </div> */}
                  <div className='font-sm'>
                    یکماهه - 50GB
                  </div>
                  <div className='font-sm'>
                    مناسب اینترنت همراه و خانگی
                  </div>
                </div>
                : 
                <div className='flex flex-col justify-center items-center border rounded p-5 w-full flex-basis-1 md:basis-1/4'>
                  <div>🇫🇮</div>
                  <div className='fw-bold'>فنلاند</div>
                  <div className='fw-bold text-red-500 flex flex-col items-center justify-center text-center'>
                    <AlertTriangle size={32} className='' />
                    خطا در دریافت اطلاعات
                  </div>
                </div>
              }
              {
                fi2Loading ?
                <>
                <div className='flex flex-col justify-center items-center border rounded p-5 w-full flex-basis-1 md:basis-1/4'>
                  <div>🇫🇮</div>
                  <div className='fw-bold'>فنلاند 2</div>
                  <div className='fw-bold'>
                    <Loader className='animate-spin animate-speed-100 text-gray-400' size={52} /> 
                  </div>
                </div>
                </>
                : fi2Inbounds && !fi2Error ?
                <div onClick={() => setSelectedServer('fi2')} style={ selectedServer && selectedServer === "fi2" ? {border: "1px solid #0db653ff", backgroundColor: "#0db65315"} : {border: "1px solid rgb(229, 231, 235)"} } className='flex flex-col justify-center items-center border rounded p-5 w-full flex-basis-1 md:basis-1/4'>
                  <div>🇫🇮</div>
                  <div className='fw-bold'>فنلاند 2</div>
                  {/* <div className='fw-bold'>
                    <span className={ fi2Inbounds > fi2Capacity ? "text-red-500" : "text-green-500" }>
                      {((fi2Inbounds * 100) / fi2Capacity).toFixed(0) + "%"}
                    </span>
                  </div> */}
                  <div className='font-sm'>
                    یکماهه - 50GB
                  </div>
                  <div className='font-sm'>
                    مناسب اینترنت همراه و خانگی
                  </div>
                </div>
                : 
                <div className='flex flex-col justify-center items-center border rounded p-5 w-full flex-basis-1 md:basis-1/4'>
                  <div>🇫🇮</div>
                  <div className='fw-bold'>فنلاند 2</div>
                  <div className='fw-bold text-red-500 flex flex-col items-center justify-center text-center'>
                    <AlertTriangle size={32} className='' />
                    خطا در دریافت اطلاعات
                  </div>
                </div>
              }
              {
                nlLoading ?
                <>
                <div className='flex flex-col justify-center items-center border rounded p-5 w-full flex-basis-1 md:basis-1/4'>
                  <div>🇳🇱</div>
                  <div className='fw-bold'>هلند</div>
                  <div className='fw-bold'>
                    <Loader className='animate-spin animate-speed-100 text-gray-400' size={52} /> 
                  </div>
                </div>
                </>
                : nlInbounds && !nlError ?
                <div onClick={() => setSelectedServer('nl')} style={ selectedServer && selectedServer === "nl" ? {border: "1px solid #0db653ff", backgroundColor: "#0db65315"} : {border: "1px solid rgb(229, 231, 235)"} } className='flex flex-col justify-center items-center border rounded p-5 w-full flex-basis-1 md:basis-1/4'>
                  <div>🇳🇱</div>
                  <div className='fw-bold'>هلند</div>
                  {/* <div className='fw-bold'>
                    <span className={ nlInbounds > nlCapacity ? "text-red-500" : "text-green-500" }>
                      {((nlInbounds * 100) / nlCapacity).toFixed(0) + "%"}
                    </span>
                  </div> */}
                  <div className='font-sm'>
                    یکماهه - 30GB
                  </div>
                  <div className='font-sm'>
                    مناسب اینترنت همراه و خانگی
                  </div>
                </div>
                : 
                <div className='flex flex-col justify-center items-center border rounded p-5 w-full flex-basis-1 md:basis-1/4'>
                  <div>🇳🇱</div>
                  <div className='fw-bold'>هلند</div>
                  <div className='fw-bold text-red-500 flex flex-col items-center justify-center text-center'>
                    <AlertTriangle size={32} className='' />
                    خطا در دریافت اطلاعات
                  </div>
                </div>
              }
              {
                plLoading ?
                <>
                <div className='flex flex-col justify-center items-center border rounded p-5 w-full flex-basis-1 md:basis-1/4'>
                  <div>🇵🇱</div>
                  <div className='fw-bold'>لهستان</div>
                  <div className='fw-bold'>
                    <Loader className='animate-spin animate-speed-100 text-gray-400' size={52} /> 
                  </div>
                </div>
                </>
                : plInbounds && !plError ?
                <div onClick={() => setSelectedServer('pl')} style={ selectedServer && selectedServer === "pl" ? {border: "1px solid #0db653ff", backgroundColor: "#0db65315"} : {border: "1px solid rgb(229, 231, 235)"} } className='flex flex-col justify-center items-center border rounded p-5 w-full flex-basis-1 md:basis-1/4'>
                  <div>🇵🇱</div>
                  <div className='fw-bold'>لهستان</div>
                  {/* <div className='fw-bold'>
                    <span className={ plInbounds > plCapacity ? "text-red-500" : "text-green-500" }>
                      {((plInbounds * 100) / plCapacity).toFixed(0) + "%"}
                    </span>
                  </div> */}
                  <div className='font-sm'>
                    یکماهه - 50GB
                  </div>
                  <div className='font-sm'>
                    مناسب اینترنت همراه و خانگی
                  </div>
                </div>
                : 
                <div className='flex flex-col justify-center items-center border rounded p-5 w-full flex-basis-1 md:basis-1/4'>
                  <div>🇵🇱</div>
                  <div className='fw-bold'>لهستان</div>
                  <div className='fw-bold text-red-500 flex flex-col items-center justify-center text-center'>
                    <AlertTriangle size={32} className='' />
                    خطا در دریافت اطلاعات
                  </div>
                </div>
              }
              {/* <>
              {
                ukLoading ?
                <>
                <div className='flex flex-col justify-center items-center border rounded p-5 w-full flex-basis-1 md:basis-1/4'>
                  <div>🇬🇧</div>
                  <div className='fw-bold'>انگلستان</div>
                  <div className='fw-bold'>
                    <Loader className='animate-spin animate-speed-100 text-gray-400' size={52} /> 
                  </div>
                </div>
                </>
                : ukInbounds && !ukError ?
                <div onClick={() => setSelectedServer('uk')} style={ selectedServer && selectedServer === "uk" ? {border: "1px solid #0db653ff", backgroundColor: "#0db65315"} : {border: "1px solid rgb(229, 231, 235)"} } className='flex flex-col justify-center items-center border rounded p-5 w-full flex-basis-1 md:basis-1/4'>
                  <div>🇬🇧</div>
                  <div className='fw-bold'>انگلستان</div>
                  <div className='fw-bold'>
                    <span className={ ukInbounds > ukCapacity ? "text-red-500" : "text-green-500" }>
                    {((ukInbounds * 100) / ukCapacity).toFixed(0) + "%"}
                    </span>
                  </div>
                  <div className='font-sm'>
                    یکماهه - 50GB
                  </div>
                  <div className='font-sm text-red-600 font-bold'>
                    فقط مناسب اینترنت ثابت خانگی - نت همراه فیلتر است
                  </div>
                </div>
                : 
                <div className='flex flex-col justify-center items-center border rounded p-5 w-full flex-basis-1 md:basis-1/4'>
                  <div>🇬🇧</div>
                  <div className='fw-bold'>انگستان</div>
                  <div className='fw-bold text-red-500 flex flex-col items-center justify-center text-center'>
                    <AlertTriangle size={32} className='' />
                    خطا در دریافت اطلاعات
                  </div>
                </div>
              }
              </> */}
              </div>
              <div className='flex flex-row items-center justify-center fw-bold bg-purple-100 rounded text-purple-500 p-3 text-center mt-3'>
                نام کاربری می‌بایست شامل حروف و یا اعداد انگلیسی باشد! استفاده از حروف فارسی، ایموجی و اعداد فارسی به پایگاه داده لطمه میزند و امکان ارسال این کاراکترها وجود ندارد!
              </div>
              <div className='flex flex-row items-center justify-between text-gray-600 mt-5'>
                <form onSubmit={createUser} className="flex flex-row w-full">

                <input pattern="^[a-zA-Z0-9\._-]+$" value={value} id="uri" type="text" className='bg-white border rounded w-full p-3 focus:outline-none focus:border-blue-500 font-default' placeholder="نام کاربری" autoComplete='off' />
                
                {loading ?
                <button className='mr-3 flex flex-row items-center justify-center bg-gray-100 text-gray-600 p-3 rounded cursor-not-allowed'>
                  <Loader className='animate-spin animate-speed-100' /> 
                </button>
                :
                <button type="submit" className='mr-3 flex flex-row items-center justify-center bg-green-100 text-green-600 p-3 rounded hover:bg-green-600 hover:text-white hover:transition transition hover:ease-linear ease-linear'>
                  <ArrowLeft className='' />
                </button>
                }
                </form>
              </div>
              </>
              : 
              <div className='flex flex-col justify-center items-center text-center bg-red-100 text-red-500 p-5 rounded border-red-500 border'>
                <div className='fw-bold text-red-500 flex flex-col items-center justify-center text-center'>
                    <AlertTriangle className='' />
                    خطا در دریافت اطلاعات
                  </div>
              </div>
            }
          </div>
        </div>
      </div>
      {data ?
      <div className="px-5">
      <div className='hover:cursor-pointer flex flex-row items-center justify-center rounded p-3 border border-blue-300 text-blue-600 bg-blue-100 hover:bg-blue-500 hover:border-blue-500 hover:text-blue-100 hover:transition transition' onClick={saveImage}>
        <Download size={16} className="ml-1" /> ذخیره اطلاعات
      </div>
      </div>
      : ""}
      <div id="resultsSection">
      {data ?
      <div className="p-5">
        <div className='border rounded'>
        <div className="p-3 bg-white rounded shadow-0 flex flex-col justify-between items-center w-full flex-nowrap">
          <QRCode
          size={256}
          value={data.uri}
          fgColor="#050505"
          bgColor='#fff'
          />
          <div className='flex flex-row w-full mt-3'>
            <CopyToClipboard text={data.uri}>
            <div className='font-small text-gray-500 items-center ml-3 bg-gray-100 p-4 h-15 rounded hover:bg-gray-400 hover:transition transition hover:text-gray-100 hover:cursor-pointer'>
              <Copy size={16} />
            </div>
            </CopyToClipboard>
            <input type="text" disabled defaultValue={data.uri} style={{direction: "ltr"}} className='w-full border h-15 p-3 rounded' />
          </div>
        </div>
        <div className="flex flex-col bg-white">
          <div className="p-3 flex flex-row items-center justify-center">
            {data.enable ? 
            <>
            <div className='bg-green-100 flex flex-row w-full justify-center items-center text-green-500 fw-bold rounded p-3'>
              <span className='animate-ping w-1 h-1 bg-green-500 rounded-full mx-2'></span>
              <p>فعال</p>
            </div>
            </>
            :
            <>
            <div className='bg-red-100 flex flex-row w-full justify-center items-center text-red-500 fw-bold rounded p-3'>
              <span className='animate-ping w-1 h-1 bg-red-500 rounded-full mx-2'></span>
              <p>غیرفعال</p>
            </div>
            </>
            }
          </div>
          <div className="flex flex-row items-center justify-between">
            <div className='flex flex-col p-5 justify-between items-center'>
              <div className='flex flex-col items-center justify-center text-gray-500 mb-5'>
                <div className='mb-1'><User size={20} /></div>
                <div className='text-gray-700 fw-bold'>{data.remark}</div>
              </div>
              <div className='flex flex-col items-center justify-center text-gray-500 mb-5'>
                <div className='mb-1'><MapPin size={20} /></div>
                <div className='text-gray-700 fw-bold'>
                  {
                    (data.streamSettings).includes('nl.aqaqomi.ir') ? "🇳🇱 هلند" : 
                    (data.streamSettings).includes('pl.aqaqomi.ir') ? "🇵🇱 لهستان" : 
                    (data.streamSettings).includes('fr.aqaqomi.ir') ? "🇫🇷 فرانسه" : 
                    (data.streamSettings).includes('fr2.aqaqomi.ir') ? "🇫🇷 فرانسه 2" : 
                    (data.streamSettings).includes('fr3.aqaqomi.ir') ? "🇫🇷 فرانسه 3" : 
                    (data.streamSettings).includes('de.aqaqomi.ir') ? "🇩🇪 آلمان" : 
                    (data.streamSettings).includes('de2.aqaqomi.ir') ? "🇩🇪 آلمان 2" : 
                    (data.streamSettings).includes('de3.aqaqomi.ir') ? "🇩🇪 آلمان 3" : 
                    (data.streamSettings).includes('fi.aqaqomi.ir') ? "🇫🇮 فنلاند" : 
                    (data.streamSettings).includes('fi2.aqaqomi.ir') ? "🇫🇮 فنلاند 2" : 
                    (data.streamSettings).includes('fr3.aqaqomi.ir') ? "🇫🇷 فرانسه 3" : 
                    (data.streamSettings).includes('uk.aqaqomi.ir') ? "🇬🇧 انگلستان" : 
                    "نامشخص"
                  }
                </div>
              </div>
              <div className='flex flex-col items-center justify-center text-gray-500 mb-5'>
                <div className='mb-1'><BarChart size={20} /></div>
                <div className='text-gray-700 fw-bold'>
                  <div className='flex flex-row items-center justify-center'>
                    {((data.up/1024/1024/1024) + (data.down/1024/1024/1024)).toFixed(2)}/{data.total === 0 ? "∞" : (data.total/1024/1024/1024).toFixed(2) + 'GB'}
                  </div>
                  <div className='flex flex-row items-center justify-center text-gray-600 font-sm'>
                    <ArrowUp width="14" className='' />{(data.up/1024/1024).toFixed(2)}MB
                    <ArrowDown width="14" className='mr-3' />{(data.down/1024/1024).toFixed(2)}MB
                  </div>
                  {data.total !== 0 ?
                  <div className='flex flex-col items-center justify-center text-gray-600 font-sm mt-1'>
                    <div className='w-full h-1 bg-gray-200 rounded'>
                      {((((data.down/1024/1024) + (data.up/1024/1024)).toFixed(2))*100/
                      ((data.total/1024/1024).toFixed(2))).toFixed(0) !== "100" ?
                      <div className="bg-blue-500 h-1 rounded" style={{width: ((((data.down/1024/1024) + (data.up/1024/1024)).toFixed(2))*100/
                      ((data.total/1024/1024).toFixed(2))).toFixed(0) + "%"}}>
                      </div>
                      :
                      <div className="bg-red-500 h-1 rounded" style={{width: ((((data.down/1024/1024) + (data.up/1024/1024)).toFixed(2))*100/
                      ((data.total/1024/1024).toFixed(2))).toFixed(0) + "%"}}>
                      </div>
                      }
                    </div>
                    <div>
                        {
                          ((((data.down/1024/1024) + (data.up/1024/1024)).toFixed(2))*100/
                          ((data.total/1024/1024).toFixed(2))).toFixed(1) + "%"
                        }
                    </div>
                  </div>
                  : ""}
                </div>
              </div>
            </div>
            <div className='flex flex-col p-5 justify-between items-center'>
              <div className='flex flex-col items-center justify-center text-gray-500 mb-5'>
                <div className='mb-1'><Paperclip size={20} /></div>
                <div className='text-gray-700 fw-bold'>{data.id}</div>
              </div>
              <div className='flex flex-col items-center justify-center text-gray-500 mb-5'>
                <div className='mb-1'><Link2 size={20} /></div>
                <div className='text-gray-700 fw-bold'>{data.port}</div>
              </div>
              <div className='flex flex-col items-center justify-center text-gray-500 mb-5'>
                <div className='mb-1'><Calendar size={20} /></div>
                <div className='text-gray-700 fw-bold'>
                  {data.expiryTime === 0 ? 
                  "∞" : 
                  <>
                    <div className='flex flex-row items-center justify-center'>
                      {moment(data.expiryTime).locale('fa').format('YYYY/MM/DD')}
                    </div>
                    <div className='flex flex-row items-center justify-center text-gray-600 font-sm'>
                      {moment(data.expiryTime).locale('fa').format('HH:mm:ss')}
                    </div>
                    <div className='flex flex-col items-center justify-center text-gray-600 font-sm'>
                    </div>
                    <div>
                      {
                        ((data.expiryTime - Date.now())/86400000).toFixed(0) > 0 ? 
                        <>
                        <div className='p-1 mt-1 flex flex-row items-center justify-center fw-bold font-sm text-green-500 bg-green-100 rounded'>
                          {
                          ((data.expiryTime - Date.now())/86400000).toFixed(0) + "روز باقیمانده" 
                          }
                        </div>
                        </>
                        :
                        <>
                        <div className='p-1 mt-1 flex flex-row items-center justify-center fw-bold font-sm text-red-500 bg-red-100 rounded'>
                          منقضی شده
                        </div>
                        </>
                      }
                    </div>
                  </>
                  }  
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
        :
        ""}
      </div>
      <div id="errorsSection">
      {createError ?
      <div className="px-5">
        <div className="bg-red-200 rounded shadow-0 border border-red-300 text-red-700 p-3 flex flex-col text-center justify-center items-center">
          <h2 className="flex p-0 items-center">
            <AlertTriangle size={48} className='' />
          </h2>
          <div className='font-lg'>خطا</div>
          <div>
              {createError}
          </div>
        </div>
      </div>
      :
      ""
      }
      </div>
    </div>
  )
}

export default CreateUser
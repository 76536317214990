import React, { useState } from 'react'
import { UserCheck, ArrowRight, Info, User, Loader, ArrowLeft, ArrowUp, ArrowDown, AlertTriangle, Paperclip, MapPin, Link2, BarChart, Calendar, Download, Copy } from 'react-feather'
import { Link } from "react-router-dom";
import axios from 'axios';
import Config from '../Config.json';
import moment from 'jalali-moment';
import QRCode from "react-qr-code";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import * as htmlToImage from 'html-to-image';
import { FileSaver } from 'file-saver';
const CheckUser = (props) => {
  const [loading, isLoading] = useState(false);
  const [data, setData] = useState(null);
  const [err, setErr] = useState(null);
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "token": `${props.token}`
    }
  };

  const handleClickScroll = () => {
    const element = document.getElementById('resultsSection');
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const handleClickScroll2 = () => {
    const element = document.getElementById('errorsSection');
    element.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    });
  };

  const saveImage = () => {
    htmlToImage.toPng(document.getElementById('resultsSection'))
    .then(function (dataUrl) {
      if (window.saveAs) {
        window.saveAs(dataUrl, data.id + " - " + data.remark + " - " + data.port);
      } else {
        FileSaver.saveAs(dataUrl, data.id + " - " + data.remark + " - " + data.port);
     }
    });
  };
  const [uriText, setUriText] = useState(null);
  const checkUser = async () => {
    const uriText = document.querySelector('#uri').value;
    if(uriText && uriText !== "") {
        setErr(null);
        setData(null);
        isLoading(true);
        await axios.post(`${Config.API_URL}/user`, {uri: uriText}, headers)
        .then(res => {
            setUriText(uriText);
            setData(res.data.res);
            isLoading(false);
            handleClickScroll()
        })
        .catch(err => {
            setData(null);
            setErr(err);
            isLoading(false);
            handleClickScroll2()
        });
    } else {
        setErr("اطلاعاتی وارد نشده!");
    }
  };
  return (
    <div className='lg:mx-20 lg:px-20'>
      <div className='flex flex-row bg-blue-700 text-white px-5 items-center justify-between py-12 rounded-b'>
        <h1 className=''>{props.title}</h1>
        <Link to="/">
          <button className='flex flex-row items-center justify-center bg-white text-blue-700 pl-2 pt-1 pb-1 pr-2 rounded border border-blue-500 transition hover:bg-blue-700 hover:text-white hover:border hover:border-white hover:transition'>
            <ArrowRight width="16" height="16" className='mx-1' /> برگشت
          </button>
        </Link>
      </div>
      <div className='p-5 flex flex-row justify-between items-center mt-5 md:-mt-12 mb-0 w-full flex-wrap md:flex-nowrap'>
        <div className='flex flex-col items-stretch justify-center w-full bg-white rounded flex-column p-5 border shadow-md mb-5 sm:flex-basis-1 md:flex-basis-1/3 hover:shadow-none transition ease-linear duration-300 hover:duration:300 hover:transition hover:ease-linear'>
          <div className='flex flex-row justify-between items-center w-100'>
            <h2>بررسی وضعیت کاربر</h2>
            <UserCheck className='bg-blue-100 text-blue-700 p-1 rounded' width="32" height="32" />
          </div>
          <div className='flex flex-row text-justify justify-start items-center bg-gray-100 p-3 mt-3 mb-5 rounded text-gray-600 text-sm'>
            <Info className='w-40 md:w-10 ml-2' /> برای دریافت وضعیت یک کاربر، از URI که قبلاً برای شما ارسال شده، استفاده نمایید. این URI معمولاً با "vmess"، "vless" و یا "trojan" شروع می‌شود. دقت داشته باشید URI را بطور کامل و بدون فاصله در ابتدا، انتها و یا میان آن paste کنید.
          </div>
          <div className='flex flex-row items-center justify-between text-gray-600 mt-5'>
            <input id="uri" type="text" className='bg-white border rounded w-full p-3 focus:outline-none focus:border-blue-500' placeholder="آدرس URI کاربر" autoComplete='off' />
            
            {loading ?
            <button className='mr-3 flex flex-row items-center justify-center bg-gray-100 text-gray-600 p-3 rounded cursor-not-allowed'>
               <Loader className='animate-spin animate-speed-100' /> 
            </button>
            :
            <button onClick={checkUser} className='mr-3 flex flex-row items-center justify-center bg-green-100 text-green-600 p-3 rounded hover:bg-green-600 hover:text-white hover:transition transition hover:ease-linear ease-linear'>
              <ArrowLeft className='' />
            </button>
            }
          </div>
        </div>
      </div>
      {data ?
      <div className="px-5">
      <div className='hover:cursor-pointer flex flex-row items-center justify-center rounded p-3 border border-blue-300 text-blue-600 bg-blue-100 hover:bg-blue-500 hover:border-blue-500 hover:text-blue-100 hover:transition transition' onClick={saveImage}>
        <Download size={16} className="ml-1" /> ذخیره اطلاعات
      </div>
      </div>
      : ""}
      <div id="resultsSection">
      {data && uriText ?
      <div className="p-5">
        <div className="p-3 bg-white rounded shadow-0 border flex flex-col">
          <div className="p-3 bg-white rounded shadow-0 flex flex-col justify-between items-center w-full flex-nowrap">
            <QRCode
            size={256}
            value={uriText}
            fgColor="#050505"
            bgColor='#fff'
            />
            <div className='flex flex-row w-full mt-3'>
              <CopyToClipboard text={uriText}>
              <div className='font-small text-gray-500 items-center ml-3 bg-gray-100 p-4 h-15 rounded hover:bg-gray-400 hover:transition transition hover:text-gray-100 hover:cursor-pointer'>
                <Copy size={16} />
              </div>
              </CopyToClipboard>
              <input type="text" disabled defaultValue={uriText} style={{direction: "ltr"}} className='w-full border h-15 p-3 rounded' />
            </div>
          </div>
          <div className="p-3 flex flex-row items-center justify-center">
            {data.enable ? 
            <>
            <div className='bg-green-100 flex flex-row w-full justify-center items-center text-green-500 fw-bold rounded p-3'>
              <span className='animate-ping w-1 h-1 bg-green-500 rounded-full mx-2'></span>
              <p>فعال</p>
            </div>
            </>
            :
            <>
            <div className='bg-red-100 flex flex-row w-full justify-center items-center text-red-500 fw-bold rounded p-3'>
              <span className='animate-ping w-1 h-1 bg-red-500 rounded-full mx-2'></span>
              <p>غیرفعال</p>
            </div>
            </>
            }
          </div>
          <div className="flex flex-row items-center justify-between">
            <div className='flex flex-col p-5 justify-between items-center'>
              <div className='flex flex-col items-center justify-center text-gray-500 mb-5'>
                <div className='mb-1'><User size={20} /></div>
                <div className='text-gray-700 fw-bold'>{data.remark}</div>
              </div>
              <div className='flex flex-col items-center justify-center text-gray-500 mb-5'>
                <div className='mb-1'><MapPin size={20} /></div>
                <div className='text-gray-700 fw-bold'>
                  {}
                  {
                    (JSON.parse(decodeURIComponent(data.streamSettings).toString()).tlsSettings.serverName).match('nl.aqaqomi.ir') ? "🇳🇱 هلند" : 
                    (JSON.parse(decodeURIComponent(data.streamSettings).toString()).tlsSettings.serverName).match('pl.aqaqomi.ir') ? "🇵🇱 لهستان" : 
                    (JSON.parse(decodeURIComponent(data.streamSettings).toString()).tlsSettings.serverName).match('fr.aqaqomi.ir') ? "🇫🇷 فرانسه" : 
                    (JSON.parse(decodeURIComponent(data.streamSettings).toString()).tlsSettings.serverName).match('fr2.aqaqomi.ir') ? "🇫🇷 فرانسه 2" : 
                    (JSON.parse(decodeURIComponent(data.streamSettings).toString()).tlsSettings.serverName).match('de.aqaqomi.ir') ? "🇩🇪 آلمان" : 
                    (JSON.parse(decodeURIComponent(data.streamSettings).toString()).tlsSettings.serverName).match('de2.aqaqomi.ir') ? "🇩🇪 آلمان 2" : 
                    (JSON.parse(decodeURIComponent(data.streamSettings).toString()).tlsSettings.serverName).match('fi.aqaqomi.ir') ? "🇫🇮 فنلاند" : 
                    (JSON.parse(decodeURIComponent(data.streamSettings).toString()).tlsSettings.serverName).match('fi2.aqaqomi.ir') ? "🇫🇮 فنلاند 2" : 
                    (JSON.parse(decodeURIComponent(data.streamSettings).toString()).tlsSettings.serverName).match('ircell.aqaqomi.ir') ? "🇫🇷 فرانسه - ایرانسل" : 
                    "نامشخص"
                  }
                </div>
              </div>
              <div className='flex flex-col items-center justify-center text-gray-500 mb-5'>
                <div className='mb-1'><BarChart size={20} /></div>
                <div className='text-gray-700 fw-bold'>
                  <div className='flex flex-row items-center justify-center'>
                    {((data.up/1024/1024/1024) + (data.down/1024/1024/1024)).toFixed(2)}/{data.total === 0 ? "∞" : (data.total/1024/1024/1024).toFixed(2) + 'GB'}
                  </div>
                  <div className='flex flex-row items-center justify-center text-gray-600 font-sm'>
                    <ArrowUp width="14" className='' />{(data.up/1024/1024).toFixed(2)}MB
                    <ArrowDown width="14" className='mr-3' />{(data.down/1024/1024).toFixed(2)}MB
                  </div>
                  {data.total !== 0 ?
                  <div className='flex flex-col items-center justify-center text-gray-600 font-sm mt-1'>
                    <div className='w-full h-1 bg-gray-200 rounded'>
                      {((((data.down/1024/1024) + (data.up/1024/1024)).toFixed(2))*100/
                      ((data.total/1024/1024).toFixed(2))).toFixed(0) !== "100" ?
                      <div className="bg-blue-500 h-1 rounded" style={{width: ((((data.down/1024/1024) + (data.up/1024/1024)).toFixed(2))*100/
                      ((data.total/1024/1024).toFixed(2))).toFixed(0) + "%"}}>
                      </div>
                      :
                      <div className="bg-red-500 h-1 rounded" style={{width: ((((data.down/1024/1024) + (data.up/1024/1024)).toFixed(2))*100/
                      ((data.total/1024/1024).toFixed(2))).toFixed(0) + "%"}}>
                      </div>
                      }
                    </div>
                    <div>
                        {
                          ((((data.down/1024/1024) + (data.up/1024/1024)).toFixed(2))*100/
                          ((data.total/1024/1024).toFixed(2))).toFixed(1) + "%"
                        }
                    </div>
                  </div>
                  : ""}
                </div>
              </div>
            </div>
            <div className='flex flex-col p-5 justify-between items-center'>
              <div className='flex flex-col items-center justify-center text-gray-500 mb-5'>
                <div className='mb-1'><Paperclip size={20} /></div>
                <div className='text-gray-700 fw-bold'>{data.id}</div>
              </div>
              <div className='flex flex-col items-center justify-center text-gray-500 mb-5'>
                <div className='mb-1'><Link2 size={20} /></div>
                <div className='text-gray-700 fw-bold'>{data.port}</div>
              </div>
              <div className='flex flex-col items-center justify-center text-gray-500 mb-5'>
                <div className='mb-1'><Calendar size={20} /></div>
                <div className='text-gray-700 fw-bold'>
                  {data.expiryTime === 0 ? 
                  "∞" : 
                  <>
                    <div className='flex flex-row items-center justify-center'>
                      {moment(data.expiryTime).locale('fa').format('YYYY/MM/DD')}
                    </div>
                    <div className='flex flex-row items-center justify-center text-gray-600 font-sm'>
                      {moment(data.expiryTime).locale('fa').format('HH:mm:ss')}
                    </div>
                    <div className='flex flex-col items-center justify-center text-gray-600 font-sm'>
                    </div>
                    <div>
                      {
                        ((data.expiryTime - Date.now())/86400000).toFixed(0) > 0 ? 
                        <>
                        <div className='p-1 mt-1 flex flex-row items-center justify-center fw-bold font-sm text-green-500 bg-green-100 rounded'>
                          {
                          ((data.expiryTime - Date.now())/86400000).toFixed(0) + "روز باقیمانده" 
                          }
                        </div>
                        </>
                        :
                        <>
                        <div className='p-1 mt-1 flex flex-row items-center justify-center fw-bold font-sm text-red-500 bg-red-100 rounded'>
                          منقضی شده
                        </div>
                        </>
                      }
                    </div>
                  </>
                  }  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        :
        ""}
      </div>
      <div id="errorsSection">
      {err ?
      <div className="p-5">
        <div className="bg-red-200 rounded shadow-0 border border-red-300 text-red-700 p-3">
          <h2 className="flex flex-row p-3 items-center"><AlertTriangle width="22" height="22" className='ml-3' /> خطا</h2>
          <div>
            <p className='fw-bold'>
              دریافت اطلاعات کاربر با مشکل مواجه شد. ممکن است نکات زیر به شما کمک کند:
            </p>
            <ul className='mr-3 list-disc'>
              <li>اطمینان حاصل کنید آدرس URI وارد شده صحیح است و ابتدا، انتها و یا میان آن فاصله‌ای وجود نداشته باشد.</li>
              <li>اطمینان حاصل کنید که آدرس ورودی را از ما دریافت نموده‌اید.</li>
              <li>آدرس ارسالی می‌بایست حتما با vless، vmess و یا trojan آغاز شود.</li>
              <li>ممکن است سرور مورد نظر شما از دسترس خارج شده باشد! از قسمت سرورها، این موضوع را پیگیری کنید.</li>
              <li>اکانت‌های غیرفعال هر 15 روز یکبار به طور کامل از روی سرورها حذف می‌شوند. برای اطلاعات بیشتر با مدیریت تماس بگیرید.</li>
            </ul>
          </div>
        </div>
      </div>
      :
      ""
      }
      </div>
    </div>
  )
}

export default CheckUser
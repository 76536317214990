import React, { useState, useEffect } from 'react'
import { ArrowRight, Info, Monitor } from 'react-feather'
import { Link } from "react-router-dom";
import axios from 'axios';
import Config from '../Config.json';

const Servers = (props) => {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        "token": `${props.token}`
      }
    };
    const [statusFI, setStatusFI] = useState(null);
    const [loadingFI, setFILoading] = useState(true);
    const [errorFI, setFIError] = useState(false);
    const getFIStatus = async () => {
        setFIError(false);
        await axios.get(`${Config.API_URL}/fi/status`, headers)
        .then(res => {
            setStatusFI(res.data.status.obj.xray.state);
            setFILoading(false);
        })
        .catch(err => {
            setFILoading(false);
            setFIError(true);
        })
    };

    const [statusFR, setStatusFR] = useState(null);
    const [loadingFR, setFRLoading] = useState(true);
    const [errorFR, setFRError] = useState(false);
    const getFRStatus = async () => {
        setFRError(false);
        await axios.get(`${Config.API_URL}/fr/status`, headers)
        .then(res => {
            setStatusFR(res.data.status.obj.xray.state);
            setFRLoading(false);
        })
        .catch(err => {
            setFRLoading(false);
            setFRError(true);
        })
    };
    const [statusFR2, setStatusFR2] = useState(null);
    const [loadingFR2, setFR2Loading] = useState(true);
    const [errorFR2, setFR2Error] = useState(false);
    const getFR2Status = async () => {
        setFR2Error(false);
        await axios.get(`${Config.API_URL}/fr2/status`, headers)
        .then(res => {
            setStatusFR2(res.data.status.obj.xray.state);
            setFR2Loading(false);
        })
        .catch(err => {
            setFR2Loading(false);
            setFR2Error(true);
        })
    };

    const [statusNL, setStatusNL] = useState(null);
    const [loadingNL, setNLLoading] = useState(true);
    const [errorNL, setNLError] = useState(false);
    const getNLStatus = async () => {
        setNLError(false);
        await axios.get(`${Config.API_URL}/nl/status`, headers)
        .then(res => {
            setStatusNL(res.data.status.obj.xray.state);
            setNLLoading(false);
        })
        .catch(err => {
            setNLLoading(false);
            setNLError(true);
        })
    };

    const [statusPL, setStatusPL] = useState(null);
    const [loadingPL, setPLLoading] = useState(true);
    const [errorPL, setPLError] = useState(false);
    const getPLStatus = async () => {
        setPLError(false);
        await axios.get(`${Config.API_URL}/pl/status`, headers)
        .then(res => {
            setStatusPL(res.data.status.obj.xray.state);
            setPLLoading(false);
        })
        .catch(err => {
            setPLLoading(false);
            setPLError(true);
        })
    };

    const [statusDE, setStatusDE] = useState(null);
    const [loadingDE, setDELoading] = useState(true);
    const [errorDE, setDEError] = useState(false);
    const getDEStatus = async () => {
        setDEError(false);
        await axios.get(`${Config.API_URL}/de/status`, headers)
        .then(res => {
            setStatusDE(res.data.status.obj.xray.state);
            setDELoading(false);
        })
        .catch(err => {
            setDELoading(false);
            setDEError(true);
        })
    };
    const [statusDE2, setStatusDE2] = useState(null);
    const [loadingDE2, setDE2Loading] = useState(true);
    const [errorDE2, setDE2Error] = useState(false);
    const getDE2Status = async () => {
        setDE2Error(false);
        await axios.get(`${Config.API_URL}/de2/status`, headers)
        .then(res => {
            setStatusDE2(res.data.status.obj.xray.state);
            setDE2Loading(false);
        })
        .catch(err => {
            setDE2Loading(false);
            setDE2Error(true);
        })
    };
    const [statusDE3, setStatusDE3] = useState(null);
    const [loadingDE3, setDE3Loading] = useState(true);
    const [errorDE3, setDE3Error] = useState(false);
    const getDE3Status = async () => {
        setDE3Error(false);
        await axios.get(`${Config.API_URL}/de3/status`, headers)
        .then(res => {
            setStatusDE3(res.data.status.obj.xray.state);
            setDE3Loading(false);
        })
        .catch(err => {
            setDE3Loading(false);
            setDE3Error(true);
        })
    };


    const [statusFI2, setStatusFi2] = useState(null);
    const [loadingFI2, setFi2Loading] = useState(true);
    const [errorFI2, setFi2Error] = useState(false);
    const getFi2Status = async () => {
        setFi2Error(false);
        await axios.get(`${Config.API_URL}/fi2/status`, headers)
        .then(res => {
          setStatusFi2(res.data.status.obj.xray.state);
          setFi2Loading(false);
        })
        .catch(err => {
          setFi2Loading(false);
          setFi2Error(true);
        })
    };


    const [statusFr3, setStatusFr3] = useState(null);
    const [loadingFr3, setFr3Loading] = useState(true);
    const [errorFr3, setFr3Error] = useState(false);
    const getFr3Status = async () => {
        setFr3Error(false);
        await axios.get(`${Config.API_URL}/fr3/status`, headers)
        .then(res => {
          setStatusFr3(res.data.status.obj.xray.state);
          setFr3Loading(false);
        })
        .catch(err => {
          setFr3Loading(false);
          setFr3Error(true);
        })
    };

    const [statusUk, setStatusUk] = useState(null);
    const [loadingUk, setUkLoading] = useState(true);
    const [errorUk, setUkError] = useState(false);
    const getUkStatus = async () => {
        setUkError(false);
        await axios.get(`${Config.API_URL}/uk/status`, headers)
        .then(res => {
          setStatusUk(res.data.status.obj.xray.state);
          setUkLoading(false);
        })
        .catch(err => {
          setUkLoading(false);
          setUkError(true);
        })
    };
      
      useEffect(() => {
        getFRStatus();
        getFR2Status();
        getNLStatus();
        getPLStatus();
        getDEStatus();
        getDE2Status();
        getDE3Status();
        getFi2Status();
        getFIStatus();
        getFr3Status();
        getUkStatus();
      }, []);
  return (
    <div className='lg:mx-20 lg:px-20'>
      <div className='flex flex-row bg-blue-700 text-white px-5 items-center justify-between py-12 rounded-b'>
        <h1 className=''>{props.title}</h1>
        <Link to="/">
          <button className='flex flex-row items-center justify-center bg-white text-blue-700 pl-2 pt-1 pb-1 pr-2 rounded border border-blue-500 transition hover:bg-blue-700 hover:text-white hover:border hover:border-white hover:transition'>
            <ArrowRight width="16" height="16" className='mx-1' /> برگشت
          </button>
        </Link>
      </div>
      <div className='p-5 flex flex-row justify-between items-center mt-5 md:-mt-12 mb-0 w-full flex-wrap md:flex-nowrap'>
        <div className='flex flex-col items-stretch justify-center w-full bg-white rounded flex-column p-5 border shadow-md mb-5 sm:flex-basis-1 md:flex-basis-1/3 hover:shadow-none transition ease-linear duration-300 hover:duration:300 hover:transition hover:ease-linear'>
          <div className='flex flex-row justify-between items-center w-100'>
            <h2>بررسی وضعیت سرورها</h2>
            <Monitor className='bg-blue-100 text-blue-700 p-1 rounded' width="32" height="32" />
          </div>
          <div className='flex flex-row text-justify justify-start items-center bg-gray-100 p-3 mt-3 mb-5 rounded text-gray-600 text-sm'>
            <Info className='w-10 ml-2' />اطلاعات مربوط به هر سرور در زیر نمایش داده می‌شود.
          </div>
        </div>
      </div>
      <div className="p-5">
        <div className="bg-white rounded shadow-0 border border-gray-200 text-gray-700 p-3">
          <div className='p-3'>
            <div className='flex flex-row justify-between items-center w-100 border px-3 py-10 rounded'>
              <div>🇫🇷 فرانسه</div>
              {
                loadingFR && !errorFR && !statusFR ?
                <div className='flex flex-row justify-center items-center text-gray-400 fw-bold rounded p-3'>در حال بررسی...</div> : 
                !loadingFR && errorFR ?
                <div className='flex flex-row justify-center items-center text-red-500 fw-bold rounded p-3'>
                  <span className='animate-ping w-1 h-1 bg-red-500 rounded-full mx-2'></span>
                  خطای سیستمی
                </div>  :
                !loadingFR && statusFR === "running" ?
                <div className='flex flex-row justify-center items-center text-green-500 fw-bold rounded p-3'>
                  <span className='animate-ping w-1 h-1 bg-green-500 rounded-full mx-2'></span>
                  فعال
                </div> 
                : 
                <div className='flex flex-row justify-center items-center text-red-500 fw-bold rounded p-3'>
                  <span className='animate-ping w-1 h-1 bg-red-500 rounded-full mx-2'></span>
                  غیرفعال
                </div> 
              }
            </div>
            <div className='flex flex-row justify-between items-center w-100 border px-3 py-10 rounded my-5'>
              <div>🇫🇷 فرانسه 2</div>
              {
                loadingFR2 && !errorFR2 && !statusFR2 ?
                <div className='flex flex-row justify-center items-center text-gray-400 fw-bold rounded p-3'>در حال بررسی...</div> : 
                !loadingFR2 && errorFR2 ?
                <div className='flex flex-row justify-center items-center text-red-500 fw-bold rounded p-3'>
                  <span className='animate-ping w-1 h-1 bg-red-500 rounded-full mx-2'></span>
                  خطای سیستمی
                </div>  :
                !loadingFR2 && statusFR2 === "running" ?
                <div className='flex flex-row justify-center items-center text-green-500 fw-bold rounded p-3'>
                  <span className='animate-ping w-1 h-1 bg-green-500 rounded-full mx-2'></span>
                  فعال
                </div> 
                : 
                <div className='flex flex-row justify-center items-center text-red-500 fw-bold rounded p-3'>
                  <span className='animate-ping w-1 h-1 bg-red-500 rounded-full mx-2'></span>
                  غیرفعال
                </div> 
              }
            </div>
            <div className='flex flex-row justify-between items-center w-100 border px-3 py-10 rounded my-5'>
              <div>🇫🇷 فرانسه 3</div>
              {
                loadingFr3 && !errorFr3 && !statusFr3 ?
                <div className='flex flex-row justify-center items-center text-gray-400 fw-bold rounded p-3'>در حال بررسی...</div> : 
                !loadingFr3 && errorFr3 ?
                <div className='flex flex-row justify-center items-center text-red-500 fw-bold rounded p-3'>
                  <span className='animate-ping w-1 h-1 bg-red-500 rounded-full mx-2'></span>
                  خطای سیستمی
                </div>  :
                !loadingFr3 && statusFr3 === "running" ?
                <div className='flex flex-row justify-center items-center text-green-500 fw-bold rounded p-3'>
                  <span className='animate-ping w-1 h-1 bg-green-500 rounded-full mx-2'></span>
                  فعال
                </div> 
                : 
                <div className='flex flex-row justify-center items-center text-red-500 fw-bold rounded p-3'>
                  <span className='animate-ping w-1 h-1 bg-red-500 rounded-full mx-2'></span>
                  غیرفعال
                </div> 
              }
            </div>
            <div className='flex flex-row justify-between items-center w-100 border px-3 py-10 rounded my-5'>
              <div>🇩🇪 آلمان</div>
              {
                loadingDE && !errorDE && !statusDE ?
                <div className='flex flex-row justify-center items-center text-gray-400 fw-bold rounded p-3'>در حال بررسی...</div> : 
                !loadingDE && errorDE ?
                <div className='flex flex-row justify-center items-center text-red-500 fw-bold rounded p-3'>
                  <span className='animate-ping w-1 h-1 bg-red-500 rounded-full mx-2'></span>
                  خطای سیستمی
                </div>  :
                !loadingDE && statusDE === "running" ?
                <div className='flex flex-row justify-center items-center text-green-500 fw-bold rounded p-3'>
                  <span className='animate-ping w-1 h-1 bg-green-500 rounded-full mx-2'></span>
                  فعال
                </div> 
                : 
                <div className='flex flex-row justify-center items-center text-red-500 fw-bold rounded p-3'>
                  <span className='animate-ping w-1 h-1 bg-red-500 rounded-full mx-2'></span>
                  غیرفعال
                </div> 
              }
            </div>
            <div className='flex flex-row justify-between items-center w-100 border px-3 py-10 rounded my-5'>
              <div>🇩🇪 آلمان 2</div>
              {
                loadingDE2 && !errorDE2 && !statusDE2 ?
                <div className='flex flex-row justify-center items-center text-gray-400 fw-bold rounded p-3'>در حال بررسی...</div> : 
                !loadingDE2 && errorDE2 ?
                <div className='flex flex-row justify-center items-center text-red-500 fw-bold rounded p-3'>
                  <span className='animate-ping w-1 h-1 bg-red-500 rounded-full mx-2'></span>
                  خطای سیستمی
                </div>  :
                !loadingDE2 && statusDE2 === "running" ?
                <div className='flex flex-row justify-center items-center text-green-500 fw-bold rounded p-3'>
                  <span className='animate-ping w-1 h-1 bg-green-500 rounded-full mx-2'></span>
                  فعال
                </div> 
                : 
                <div className='flex flex-row justify-center items-center text-red-500 fw-bold rounded p-3'>
                  <span className='animate-ping w-1 h-1 bg-red-500 rounded-full mx-2'></span>
                  غیرفعال
                </div> 
              }
            </div>
            <div className='flex flex-row justify-between items-center w-100 border px-3 py-10 rounded my-5'>
              <div>🇩🇪 آلمان 3</div>
              {
                loadingDE3 && !errorDE3 && !statusDE3 ?
                <div className='flex flex-row justify-center items-center text-gray-400 fw-bold rounded p-3'>در حال بررسی...</div> : 
                !loadingDE3 && errorDE3 ?
                <div className='flex flex-row justify-center items-center text-red-500 fw-bold rounded p-3'>
                  <span className='animate-ping w-1 h-1 bg-red-500 rounded-full mx-2'></span>
                  خطای سیستمی
                </div>  :
                !loadingDE3 && statusDE3 === "running" ?
                <div className='flex flex-row justify-center items-center text-green-500 fw-bold rounded p-3'>
                  <span className='animate-ping w-1 h-1 bg-green-500 rounded-full mx-2'></span>
                  فعال
                </div> 
                : 
                <div className='flex flex-row justify-center items-center text-red-500 fw-bold rounded p-3'>
                  <span className='animate-ping w-1 h-1 bg-red-500 rounded-full mx-2'></span>
                  غیرفعال
                </div> 
              }
            </div>
            <div className='flex flex-row justify-between items-center w-100 border px-3 py-10 rounded my-5'>
              <div>🇫🇮 فنلاند</div>
              {
                loadingFI && !errorFI && !statusFI ?
                <div className='flex flex-row justify-center items-center text-gray-400 fw-bold rounded p-3'>در حال بررسی...</div> : 
                !loadingFI && errorFI ?
                <div className='flex flex-row justify-center items-center text-red-500 fw-bold rounded p-3'>
                  <span className='animate-ping w-1 h-1 bg-red-500 rounded-full mx-2'></span>
                  خطای سیستمی
                </div>  :
                !loadingFI && statusFI === "running" ?
                <div className='flex flex-row justify-center items-center text-green-500 fw-bold rounded p-3'>
                  <span className='animate-ping w-1 h-1 bg-green-500 rounded-full mx-2'></span>
                  فعال
                </div> 
                : 
                <div className='flex flex-row justify-center items-center text-red-500 fw-bold rounded p-3'>
                  <span className='animate-ping w-1 h-1 bg-red-500 rounded-full mx-2'></span>
                  غیرفعال
                </div> 
              }
            </div>
            <div className='flex flex-row justify-between items-center w-100 border px-3 py-10 rounded my-5'>
              <div>🇫🇮 فنلاند 2</div>
              {
                loadingFI2 && !errorFI2 && !statusFI2 ?
                <div className='flex flex-row justify-center items-center text-gray-400 fw-bold rounded p-3'>در حال بررسی...</div> : 
                !loadingFI2 && errorFI2 ?
                <div className='flex flex-row justify-center items-center text-red-500 fw-bold rounded p-3'>
                  <span className='animate-ping w-1 h-1 bg-red-500 rounded-full mx-2'></span>
                  خطای سیستمی
                </div>  :
                !loadingFI2 && statusFI2 === "running" ?
                <div className='flex flex-row justify-center items-center text-green-500 fw-bold rounded p-3'>
                  <span className='animate-ping w-1 h-1 bg-green-500 rounded-full mx-2'></span>
                  فعال
                </div> 
                : 
                <div className='flex flex-row justify-center items-center text-red-500 fw-bold rounded p-3'>
                  <span className='animate-ping w-1 h-1 bg-red-500 rounded-full mx-2'></span>
                  غیرفعال
                </div> 
              }
            </div>
            <div className='flex flex-row justify-between items-center w-100 border px-3 py-10 rounded my-5'>
              <div>🇳🇱 هلند</div>
              {
                loadingNL && !errorNL && !statusNL ?
                <div className='flex flex-row justify-center items-center text-gray-400 fw-bold rounded p-3'>در حال بررسی...</div> : 
                !loadingNL && errorNL ?
                <div className='flex flex-row justify-center items-center text-red-500 fw-bold rounded p-3'>
                  <span className='animate-ping w-1 h-1 bg-red-500 rounded-full mx-2'></span>
                  خطای سیستمی
                </div>  :
                !loadingNL && statusNL === "running" ?
                <div className='flex flex-row justify-center items-center text-green-500 fw-bold rounded p-3'>
                  <span className='animate-ping w-1 h-1 bg-green-500 rounded-full mx-2'></span>
                  فعال
                </div> 
                : 
                <div className='flex flex-row justify-center items-center text-red-500 fw-bold rounded p-3'>
                  <span className='animate-ping w-1 h-1 bg-red-500 rounded-full mx-2'></span>
                  غیرفعال
                </div> 
              }
            </div>
            <div className='flex flex-row justify-between items-center w-100 border px-3 py-10 rounded my-5'>
              <div>🇵🇱 لهستان</div>
              {
                loadingPL && !errorPL && !statusPL ?
                <div className='flex flex-row justify-center items-center text-gray-400 fw-bold rounded p-3'>در حال بررسی...</div> : 
                !loadingPL && errorPL ?
                <div className='flex flex-row justify-center items-center text-red-500 fw-bold rounded p-3'>
                  <span className='animate-ping w-1 h-1 bg-red-500 rounded-full mx-2'></span>
                  خطای سیستمی
                </div>  :
                !loadingPL && statusPL === "running" ?
                <div className='flex flex-row justify-center items-center text-green-500 fw-bold rounded p-3'>
                  <span className='animate-ping w-1 h-1 bg-green-500 rounded-full mx-2'></span>
                  فعال
                </div> 
                : 
                <div className='flex flex-row justify-center items-center text-red-500 fw-bold rounded p-3'>
                  <span className='animate-ping w-1 h-1 bg-red-500 rounded-full mx-2'></span>
                  غیرفعال
                </div> 
              }
            </div>
            <div className='flex flex-row justify-between items-center w-100 border px-3 py-10 rounded my-5'>
              <div>🇬🇧 انگلستان</div>
              {
                loadingNL && !errorNL && !statusNL ?
                <div className='flex flex-row justify-center items-center text-gray-400 fw-bold rounded p-3'>در حال بررسی...</div> : 
                !loadingNL && errorNL ?
                <div className='flex flex-row justify-center items-center text-red-500 fw-bold rounded p-3'>
                  <span className='animate-ping w-1 h-1 bg-red-500 rounded-full mx-2'></span>
                  خطای سیستمی
                </div>  :
                !loadingNL && statusNL === "running" ?
                <div className='flex flex-row justify-center items-center text-green-500 fw-bold rounded p-3'>
                  <span className='animate-ping w-1 h-1 bg-green-500 rounded-full mx-2'></span>
                  فعال
                </div> 
                : 
                <div className='flex flex-row justify-center items-center text-red-500 fw-bold rounded p-3'>
                  <span className='animate-ping w-1 h-1 bg-red-500 rounded-full mx-2'></span>
                  غیرفعال
                </div> 
              }
              </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Servers
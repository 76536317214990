import React, { useEffect, useState } from 'react'
import axios from 'axios';
import Config from '../Config.json';
import { Loader, AlertTriangle, Copy, RefreshCw, Calendar, DownloadCloud, User, Frown, MapPin, Paperclip, Link2 } from 'react-feather'
import {CopyToClipboard} from 'react-copy-to-clipboard';
const base64json = require('base64json');

const FinlandUsers2 = (props) => {
    const [users, setUsers] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [updateLoading, setUpdateLoading] = useState(false);
    const [updateError, setUpdateError] = useState(null);
    const [updateComplete, setUpdateComplete] = useState(null);
    const reviseUser = async (userid, userRemark, userPort, userUUID, userServer) => {
        setUpdateLoading(true);
        const body = {
            id: userid,
            remark: userRemark,
            port: userPort,
            uuid: userUUID 
        };
        const headers = {
          headers: {
            "Content-Type": "application/json",
            "token": `${props.token}`
          }
        };
        await axios.post(`${Config.API_URL}/${userServer.split('.')[0]}/revise`, body, headers)
          .then(res => {
            setUpdateLoading(false);
            setUpdateComplete(true);
            setTimeout(setUpdateComplete(false), 2500);
          })
          .catch(err => {
            setUpdateLoading(false);
            setUpdateError('خطا در بروزرسانی');
          });
      };

      const [deleteLoading, setDeleteLoading] = useState(false);
    const [deleteError, setDeleteError] = useState(null);
    const [deleteComplete, setDeleteComplete] = useState(null);
    const deleteUser = async (userid, userRemark, userPort, userServer) => {
        setDeleteLoading(true);
        const body = {
            id: userid,
            remark: userRemark,
            port: userPort
        };
        const headers = {
          headers: {
            "Content-Type": "application/json",
            "token": `${props.token}`
          }
        };
        await axios.post(`${Config.API_URL}/${userServer.split('.')[0]}/delete`, body, headers)
          .then(res => {
            setDeleteLoading(false);
            setDeleteComplete(true);
            setTimeout(setDeleteComplete(false), 2500);
          })
          .catch(err => {
            setUpdateLoading(false);
            setDeleteError('خطا در حذف');
          });
      };

    useEffect(() => {
        const headers = {
            headers: {
                "Content-Type": "application/json",
                "token": `${props.token}`
            }
        };
        try {
            axios.post(`${Config.API_URL}/fi2/userslist`, {}, headers)
            .then(res => {
                setUsers(res.data.users);
                props.fi2Count(res.data.users.length)
                setLoading(false);
                setError(null);
            });
        } catch (err) {
            setUsers(null);
            setLoading(false);
            setError(true);
        }
    },[props, users]);
  return (
    <div className='mt-5'>
        {
            loading ? 
            <>
                <div className='flex flex-col justify-center items-center w-full mt-1 rounded p-5'>
                    <Loader size={32} className='text-gray-300 animate-spin' />
                    <div className='font-sm text-gray-600'>
                        شکیبا باشید
                    </div>
                    <div className='fw-bold text-gray-600'>
                        درحال دریافت اطلاعات از سرور فنلاند 2
                    </div>
                </div>
            </>
            : error ?
            <>
                <div className='flex flex-col justify-center items-center w-full border mt-1 rounded p-5 bg-red-50 border-red-50'>
                    <AlertTriangle size={32} className='text-red-500' />
                    <div className='fw-bold text-red-600'>
                         خطا در دریافت اطلاعات از سرور فنلاند 2
                    </div>
                    <div className='font-sm text-red-600'>
                        به محض برقراری ارتباط، داده ها نمایش داده خواهند شد
                    </div>
                </div>
            </>
            : users && users.length > 0 ? 
            <div className='flex flex-row-reverse flex-wrap'>
            {users.map(user => 
                <div className='flex flex-row basis-full md:basis-1/4 userRow' key={user.id} id={user.remark} totaltraffic={((((user.down/1024/1024) + (user.up/1024/1024)).toFixed(2))*100/((user.total/1024/1024).toFixed(2))).toFixed(0)} expirytime={((user.expiryTime - Date.now())/86400000).toFixed(0)} userstatus={user.enable ? "1" : "0"} >
                    <div className='flex flex-col w-full m-3 border rounded'>
                        <div className='border-b mb-5 flex flex-row-reverse items-center justify-between p-2'>
                            <div className='flex-row-reverse flex items-center'>
                                <User size={18} className="mr-1" /> 
                                {user.remark}
                                <span className='font-sm mx-1 bg-purple-100 border border-purple-400 text-purple-400 rounded px-1'>
                                    {user.protocol}
                                </span>
                            </div>
                            <div className=''>
                                {user.enable ?
                                <div className='justify-center items-center fw-bold rounded p-2 flex flex-row text-green-400 font-sm'>
                                    <div className='animate-ping w-1 h-1 bg-green-700 rounded-full mx-1'></div>
                                </div>
                                :
                                <div className='justify-center items-center fw-bold rounded p-2 flex flex-row text-red-400 font-sm'>
                                    <div className='animate-ping w-1 h-1 bg-red-700 rounded-full mx-1'></div>
                                </div>
                                }
                            </div>
                        </div>
                        <div className='p-2'>
                            <div className='flex flex-row text-gray-600 fw-bold mb-2 items-center'>
                                <Paperclip color="#bbb" size={16} className="ml-1" /> {user.id}
                            </div>
                            <div className='flex flex-row text-gray-600 fw-bold mb-2 items-center'>
                                <MapPin color="#bbb" size={16} className="ml-1" /> فنلاند 2 🇫🇮
                            </div>
                            <div className='flex flex-row text-gray-600 fw-bold mb-2 items-center'>
                                <Link2 color="#bbb" size={16} className="ml-1" /> {user.port}
                            </div>
                            <div className="flex flex-row text-gray-600 fw-bold mb-2 items-center">
                                <Calendar color="#bbb" size={16} className="ml-1" />
                                {user.expiryTime === 0 ? 
                                    <div className='text-gray-700'>
                                    ∞ نامحدود
                                    </div>
                                : 
                                <>
                                    {
                                        ((user.expiryTime - Date.now())/86400000).toFixed(0) > 0 ? 
                                        <>
                                            {
                                                ((user.expiryTime - Date.now())/86400000).toFixed(0) + " روز باقیمانده" 
                                            }
                                        </>
                                        :
                                        <>
                                        <div className='text-red-300'>
                                            اتمام اعتبار
                                        </div>
                                        </>
                                    }
                                    </>
                                }
                            </div>
                            <div className="flex flex-row text-gray-600 fw-bold mb-2 items-center">
                                <DownloadCloud  color="#bbb" size={16} className="ml-1" />
                                {user.total === 0 ?
                                    <div className='text-gray-700'>
                                    ∞ نامحدود
                                    </div>
                                : ((((user.down/1024/1024) + (user.up/1024/1024)).toFixed(2))*100/((user.total/1024/1024).toFixed(2))).toFixed(0) < 100 ?
                                    <>
                                        {
                                            ((((user.down/1024/1024) + (user.up/1024/1024)).toFixed(2))*100/((user.total/1024/1024).toFixed(2))).toFixed(1) + "%"
                                        }
                                    </>
                                :
                                <div className='text-red-300'>
                                    اتمام حجم
                                </div>
                                }
                            </div>
                        </div>
                        <div className='border-t p-2'>
                            {user.protocol === 'vmess' || user.protocol === 'vless' || user.protocol === 'trojan' ?
                                <div className='flex flex-row items-center justify-between'>
                                    <CopyToClipboard text=
                                    {user.protocol === "vmess" ? "vmess://" + 
                                    base64json.stringify(
                                            {
                                            "v": "2",
                                            "ps": user.remark,
                                            "add": "fi2.aqaqomi.ir",
                                            "port": user.port,
                                            "id": JSON.parse(decodeURIComponent(user.settings).toString()).clients[0].id,
                                            "aid": 0,
                                            "net": "ws",
                                            "type": "none",
                                            "host": "",
                                            "path": "/",
                                            "tls": "tls"
                                            }
                                            , null, 2) : 
                                            user.protocol === "vless" ? "vless://" + 
                                            JSON.parse(decodeURIComponent(user.settings).toString()).clients[0].id + "@fi2.aqaqomi.ir:" + user.port + "?type=" + 
                                            JSON.parse(decodeURIComponent(user.streamSettings).toString()).network + "&security=" + 
                                            JSON.parse(decodeURIComponent(user.streamSettings).toString()).security + "&flow=" +
                                            JSON.parse(decodeURIComponent(user.settings).toString()).clients[0].flow + "#" + user.remark
                                            :
                                            user.protocol === "trojan" ? "trojan://" +
                                            JSON.parse(decodeURIComponent(user.settings).toString()).clients[0].password + "@fi2.aqaqomi.ir:" + user.port + "#" + user.remark
                                            : ""
                                            }>
                                            <div className='flex flex-row justify-center items-center font-small text-blue-500 items-center ml-3 bg-blue-100 p-2 h-15 rounded hover:bg-blue-400 hover:transition transition hover:text-blue-100 hover:cursor-pointer'>
                                            <Copy size={16}  />
                                        </div>
                                    </CopyToClipboard>
                                    <div className='flex flex-row items-left justify-end'>
                                    {(!user.enable || ((((user.down/1024/1024) + (user.up/1024/1024)).toFixed(2))*100/((user.total/1024/1024).toFixed(2))).toFixed(0) === 100.0 || ((user.expiryTime - Date.now())/86400000).toFixed(0) === 0 ) && (user.total !== 0 && user.expiryTime !== 0) ? 
                                        deleteLoading ?
                                        <div className='flex flex-row items-center text-blue-600 bg-blue-100 p-2 rounded font-sm cursor-not-allowed'>
                                            در حال حذف
                                        </div>
                                        : deleteError ?
                                        <div className='flex flex-row items-center text-red-600 bg-red-100 p-2 rounded font-sm cursor-not-allowed'>
                                            خطای حذف
                                        </div>
                                        : deleteComplete ?
                                        <div className='flex flex-row items-center text-green-600 bg-green-100 p-2 rounded font-sm cursor-pointer'>
                                            حذف موفق
                                        </div>
                                        :
                                        <div onClick={() => deleteUser(user.id, user.remark, user.port, JSON.parse(decodeURIComponent(user.streamSettings).toString()).tlsSettings.serverName)} className='flex bg-red-200 text-red-600 mx-1 items-center justify-center p-2 font-sm rounded cursor-pointer hover:bg-red-600 hover:text-white'>
                                            X حذف کاربر
                                        </div>
                                        : ""
                                        }
                                    <div>
                                        {
                                            (!user.enable || ((((user.down/1024/1024) + (user.up/1024/1024)).toFixed(2))*100/((user.total/1024/1024).toFixed(2))).toFixed(0) > 95.0 || ((user.expiryTime - Date.now())/86400000).toFixed(0) < 3 ) && (user.total !== 0 && user.expiryTime !== 0) ?
                                            
                                            updateLoading ?
                                            <div className='flex flex-row items-center text-blue-600 bg-blue-100 p-2 rounded font-sm cursor-not-allowed'>
                                                در حال بروزرسانی
                                            </div>
                                            : updateError ?
                                            <div className='flex flex-row items-center text-red-600 bg-red-100 p-2 rounded font-sm cursor-not-allowed'>
                                                خطای بروزرسانی
                                            </div>
                                            : updateComplete ?
                                            <div className='flex flex-row items-center text-green-600 bg-green-100 p-2 rounded font-sm cursor-pointer'>
                                                بروزرسانی موفق
                                            </div>
                                            :
                                            <div onClick={() => reviseUser(user.id, user.remark, user.port, JSON.parse(decodeURIComponent(user.settings).toString()).clients[0].id, JSON.parse(decodeURIComponent(user.streamSettings).toString()).tlsSettings.serverName)} className='flex flex-row items-center text-green-600 bg-green-100 p-2 rounded font-sm cursor-pointer'>
                                                <RefreshCw size={16} className="ml-1" />
                                                <span>تمدید کاربر</span>
                                            </div>
                                                
                                        :
                                            <div className='flex flex-row items-center text-gray-600 bg-gray-100 p-2 rounded font-sm cursor-not-allowed'>
                                                <RefreshCw size={16} className="ml-1" />
                                                <span>تمدید کاربر</span>
                                            </div>
                                        }
                                    </div>
                                    </div>
                                </div>
                            : ""
                            }
                        </div>
                    </div>
                </div>
            )}
            </div>
            :
            <>
                <div className='flex flex-col justify-center items-center w-full mt-1 rounded p-5 bg-gray-50 border-grayred-50'>
                    <Frown size={32} className='text-gray-500' />
                    <div className='fw-bold text-gray-600 mt-2'>
                         هنوز کاربری بر روی سرور فنلاند 2 ایجاد نکرده‌اید!
                    </div>
                </div>
            </>
        }
    </div>
  )
}

export default FinlandUsers2
import React from 'react'
import User from './User';
import Brand from './Brand';


const Header = (props) => {
  return (
    <div className='flex flex-row bg-white shadow-md border-b px-5 pt-3 pb-3 lg:px-40 items-center justify-between'>
      <User token={props.token} logout={props.logout} />
      <Brand />
    </div>
  )
}

export default Header
import React, { useEffect, useState } from 'react'
import { ArrowRight, DollarSign, Loader } from 'react-feather'
import { Link } from "react-router-dom";
import axios from 'axios';
import Config from '../Config.json';
import moment from 'jalali-moment';

const AddCredit = (props) => {
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "token": `${props.token}`
    }
  };
  const [logs, setLogs] = useState(""); 
  useEffect(() => {
    axios.get(`${Config.API_URL}/reseller`, headers)
    .then (result => {
      console.log(result);
      axios.get(`https://resellers-admin-backend.onrender.com/logs/${result.data.username}`)
      .then (resp => {
        console.log(resp.data);
        setLogs(resp.data)
      })
      .catch(error => {
          console.error(error);
      });
    })
    .catch(err => {
        console.error(err);
    });
  }, [])
  return (
    <div className='lg:mx-20 lg:px-20'>
      <div className='flex flex-row bg-blue-700 text-white px-5 items-center justify-between py-12 rounded-b'>
        <h1 className=''>{props.title}</h1>
        <Link to="/">
          <button className='flex flex-row items-center justify-center bg-white text-blue-700 pl-2 pt-1 pb-1 pr-2 rounded border border-blue-500 transition hover:bg-blue-700 hover:text-white hover:border hover:border-white hover:transition'>
            <ArrowRight width="16" height="16" className='mx-1' /> برگشت
          </button>
        </Link>
      </div>
      <div className='p-5 flex flex-row justify-between items-center mt-10 md:-mt-12 mb-0 w-full flex-wrap md:flex-nowrap'>

        <div className='flex flex-col items-stretch justify-center w-full bg-white rounded flex-column p-5 border shadow-md mb-5 sm:flex-basis-1 md:flex-basis-1/3 hover:shadow-none transition ease-linear duration-300 hover:duration:300 hover:transition hover:ease-linear'>
          <div className='flex flex-row justify-between items-center w-100'>
            <h2>گزارش اعتبار</h2>
            <DollarSign className='bg-blue-100 text-blue-700 p-1 rounded' width="32" height="32" />
          </div>
          {logs ?
          <div className='flex flex-col items-center justify-between text-gray-600 mt-5 w-full'>
              <div className="flex flex-row items-center w-full p-5 bg-blue-700 text-white rounded">
                <div className='fw-bold w-2/4'>توضیحات</div>
                <div className='fw-bold w-1/4'>تاریخ</div>
                <div className='fw-bold w-1/4'>اعتبار</div>
              </div>
            {logs.map(log =>
              <div key={log.id} className="flex flex-row items-center w-full border rounded m-1 h-24 p-2">
                <div className='border-l w-2/4 h-24 flex flex-row items-center p-1'>{log.description}</div>
                <div className='border-l w-1/4 h-24 flex flex-col items-center p-1 justify-center'>
                  <div>{moment(log.created_at).locale('fa').format('YYYY/MM/DD')}</div>
                  <div>{moment(log.created_at).locale('en').format('YYYY/MM/DD')}</div>
                </div>
                <div className='h-24 w-1/4 p-1 flex items-center'>{log.credit}</div>
              </div>  
            )}
          </div>
          :
          <div className='flex flex-col items-center justify-center text-gray-600 mt-5 bg-gray-100 p-5'>
            <Loader className='animate-spin animate-speed-100 text-gray-400' size={48} />
            <div className='text-gray-600'>
              درحال دریافت اطلاعات
            </div>
          </div>
          }
        </div>
      </div>
    </div>
  )
}

export default AddCredit